import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { Credential } from 'src/app/models/credential';
import { CredentialType } from 'src/app/models/credential-type';
import { Person } from 'src/app/models/person';
import { ApiCredentialsService } from 'src/app/services/api-credentials.service';
import { ApiPersonsService } from 'src/app/services/api-persons.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-credential-dialog',
  templateUrl: './credential-dialog.component.html',
  styleUrls: ['./credential-dialog.component.scss'],
})
export class CredentialDialogComponent implements OnInit, OnChanges {
  enrollmentForm: UntypedFormGroup;
  personData: Person;
  credential: Credential | undefined = undefined;
  credentials: Credential[] = [];
  credentialIndex: number = 0;
  credentialTypes: CredentialType[] = [];

  extendExpiration: boolean = false;
  validUntilNotSetted: boolean = false;

  creatingNewCredential: boolean = false;
  hasNoCredentials: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; personId: number },
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    private APICredentials: ApiCredentialsService,
    private APIPersons: ApiPersonsService,
    private fb: UntypedFormBuilder,
    private ui: UserInterfaceService,
    private cdr: ChangeDetectorRef,
  ) {
    this.enrollmentForm = this.fb.group({
      enabled: [null, Validators.required],
      type: [null, Validators.required],
      valid_until: [null],
      id_person: [this.data.personId, Validators.required],
      id: [null],
    });

    this.ui.spin$.next(true);
    this.enrollmentForm.statusChanges.subscribe();

    this.enrollmentForm.get('enabled')!.valueChanges.subscribe(value => {
      if (value === true) {
        this.enrollmentForm.get('type')!.enable();
        this.enrollmentForm.get('valid_until')!.enable();
      } else {
        this.enrollmentForm.get('type')!.disable();
        this.enrollmentForm.get('valid_until')!.disable();
      }
      this.enrollmentForm.get('type')!.updateValueAndValidity();
      this.enrollmentForm.get('valid_until')!.updateValueAndValidity();
    });
  }

  async ngOnInit(): Promise<void> {
    this.getTypes();
    this.getPerson();
    await this.getAllCredentials();
    await this.getCredential();
    if (this.credentials.length == 0) {
      this.hasNoCredentials = true;
    }
    this.updateDialogSelectors();
    this.ui.spin$.next(false);
  }

  ngOnChanges() {
    this.updateDialogSelectors();
  }

  getPerson() {
    this.APIPersons.getById(this.data.personId).subscribe(response => {
      this.personData = response.data;
    });
  }

  async getAllCredentials() {
    try {
      this.credentials = await firstValueFrom(
        this.APICredentials.getAll(this.data.personId),
      ).then(response => {
        if (response.data?.enabled)
          response.data.enabled = response.data.enabled === '1';

        this.enrollmentForm.patchValue(response.data);
        if (response.data?.valid_until === null || !response.data) {
          this.validUntilNotSetted = true;
          this.enrollmentForm
            .get('valid_until')
            ?.setValidators([Validators.required]);
          this.enrollmentForm.get('valid_until')?.updateValueAndValidity();
        }
        return response.data;
      });
    } catch (error) {
      console.error(error);
    }
  }
  async getCredential(credential_id: number = 0) {
    try {
      if (this.credentials !== undefined && this.credentials?.length > 0) {
        this.credential = this.credentials[credential_id];
      }
    } catch (error) {
      console.error(error);
    }
  }

  createNewCredential() {
    this.creatingNewCredential = true;
    this.enrollmentForm.reset();
    this.enrollmentForm.get('enabled')?.setValue('0');
    this.enrollmentForm.get('type')?.enable();
    this.enrollmentForm.get('valid_until')?.enable();
    this.enrollmentForm.get('type')?.enable();
    this.enrollmentForm.get('id_person')?.setValue(this.data.personId);
    this.enrollmentForm.get('id')?.setValue(null);
    this.extendExpiration = true;
    this.credential = this.enrollmentForm.getRawValue();
    if (this.credentials !== undefined && this.credential !== undefined) {
      this.credential.status = 'not-configured';
      this.credentials.push(this.credential);
    }

    this.credentialIndex++;

    this.cdr.detectChanges(); // Manually trigger change detection
    this.updateDialogSelectors();
  }

  getNextCredential() {
    if (
      this.credentialIndex !== undefined &&
      this.credentialIndex < this.credentials?.length - 1
    ) {
      this.credentialIndex++;
      // this.credential = this.credentials[this.credentialIndex];
      this.credential = { ...this.credentials[this.credentialIndex] };
      //update status form
      this.updateDialogSelectors();
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  getPreviousCredential() {
    if (this.credentialIndex !== undefined && this.credentialIndex > 0) {
      this.credentialIndex--;
      // this.credential = this.credentials[this.credentialIndex];
      this.credential = { ...this.credentials[this.credentialIndex] };
      //update status form
      this.updateDialogSelectors();
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  updateDialogSelectors() {
    this.enrollmentForm
      .get('enabled')
      ?.setValue(this.credential?.enabled == '1', false);
    this.enrollmentForm.get('type')?.setValue(this.credential?.type);
  }

  getTypes() {
    this.APICredentials.getTypes().subscribe(response => {
      this.credentialTypes = response.data;
    });
  }

  changeExpiration(event: Event) {
    if ((event.target as HTMLInputElement).value == 'yes') {
      this.extendExpiration = true;
      this.enrollmentForm
        .get('valid_until')
        ?.setValidators([Validators.required]);
    } else {
      this.extendExpiration = false;
      this.enrollmentForm.get('valid_until')?.clearValidators();
    }
    this.enrollmentForm.get('valid_until')?.updateValueAndValidity();
  }

  save(): void {
    this.creatingNewCredential = false;

    if (this.enrollmentForm.valid) {
      this.ui.spin$.next(true);
      // let regRequest: Credential = this.enrollmentForm.getRawValue();
      //! TODO: Caso en que el usuario no tiene ninguna credencial
      const regRequest: Credential = {
        enabled: this.enrollmentForm.get('enabled')?.value,
        id: this.credentials[this.credentialIndex]?.id || 0,
        id_person: this.enrollmentForm.get('id_person')?.value,
        status: this.credential?.status,
        type: this.enrollmentForm.get('type')?.value,
        valid_until:
          this.enrollmentForm.get('valid_until')?.value ||
          this.credentials[this.credentialIndex].valid_until,
        updated_at: this.credential?.updated_at || '',
      };

      this.APICredentials.save(regRequest).subscribe(response => {
        if (response.status == 201) {
          alert('Credencial Creada correctamente');
          this.dialogRef.close();
        } else if (response.status == 200 && response.code == 200) {
          alert('Credencial Modificada correctamente');
        } else if (response.status == 200 && response.code == 409) {
          alert(response.msg);
        } else {
          alert('Error al modificar la credencial: ' + response.msg);
        }

        this.ui.spin$.next(false);

        // return response.msg;
      });
    } else {
      Object.keys(this.enrollmentForm.controls).forEach(field => {
        const control = this.enrollmentForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
