import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { AnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { DatePickerModule } from 'primeng/datepicker';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { SelectModule } from 'primeng/select';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabsModule } from 'primeng/tabs';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';

// App components and pages
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { CourseDetailsDialogComponent } from './components/course-details-dialog/course-details-dialog.component';
import { CoursesListDialogComponent } from './components/courses-list-dialog/courses-list-dialog.component';
import { CredentialComponent } from './components/credential/credential.component';
import { CredentialDialogComponent } from './components/credential-dialog/credential-dialog.component';
import { CredentialHorizontalComponent } from './components/credential-horizontal/credential-horizontal.component';
import { CredentialListTableComponent } from './components/credential-list-table/credential-list-table.component';
import { CredentialTypeManagerDialogComponent } from './components/credential-type-manager-dialog/credential-type-manager-dialog.component';
import { CredentialVerticalComponent } from './components/credential-vertical/credential-vertical.component';
import { CustomChartComponent } from './components/custom-chart/custom-chart.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { EnrollmentDialogComponent } from './components/enrollment-dialog/enrollment-dialog.component';
import { FeesHistoryTableComponent } from './components/fees-history-table/fees-history-table.component';
import { FileButtonComponent } from './components/file-button/file-button.component';
import { FlowRequestDialogComponent } from './components/flow-request-dialog/flow-request-dialog.component';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { LicenseCreateDialogComponent } from './components/license-create-dialog/license-create-dialog.component';
import { LicenseDialogComponent } from './components/license-dialog/license-dialog.component';
import { LicenseHorizontalComponent } from './components/license-horizontal/license-horizontal.component';
import { LicenseListTableComponent } from './components/license-list-table/license-list-table.component';
import { CustomLottieComponent } from './components/lottie/lottie.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PaymentFeesComponent } from './components/payment-fees/payment-fees.component';
import { ReceiptsByStatusTableComponent } from './components/receipts-by-status-table/receipts-by-status-table.component';
import { RecordDialogComponent } from './components/record-dialog/record-dialog.component';
import { RecordListTableComponent } from './components/record-list-table/record-list-table.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { RequestsListTableComponent } from './components/requests-list-table/requests-list-table.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SignatureDialogComponent } from './components/signature-dialog/signature-dialog.component';
import { StudentFileComponent } from './components/student-file/student-file.component';
import { StudentFileDialogComponent } from './components/student-file-dialog/student-file-dialog.component';
import { StudentsByStatusTableComponent } from './components/students-by-status-table/students-by-status-table.component';
import { TestComponent } from './components/test/test.component';
import { UserProfileFormComponent } from './components/user-profile-form/user-profile-form.component';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { AddPaymentReceiptComponent } from './pages/add-payment-receipt/add-payment-receipt.component';
import { AmateurLicenseFormComponent } from './pages/amateur-license-form/amateur-license-form.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ChangeProfileRequestsComponent } from './pages/change-profile-requests/change-profile-requests.component';
import { ChangeProfileRequestsDetailsComponent } from './pages/change-profile-requests-details/change-profile-requests-details.component';
import { ContactInformationComponent } from './pages/contact-information/contact-information.component';
import { CoursesFormComponent } from './pages/courses-form/courses-form.component';
import { CoursesListComponent } from './pages/courses-list/courses-list.component';
import { CoursesReportComponent } from './pages/courses-report/courses-report.component';
import { CredentialTypeManagerComponent } from './pages/credential-type-manager/credential-type-manager.component';
import { CredentialsListComponent } from './pages/credentials-list/credentials-list.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeComponent } from './pages/home/home.component';
import { LicenseListComponent } from './pages/licenses-list/licenses-list.component';
import { LoginComponent } from './pages/login/login.component';
import { PaymentReceiptDetailsComponent } from './pages/payment-receipt-details/payment-receipt-details.component';
import { PaymentReceiptsListComponent } from './pages/payment-receipts-list/payment-receipts-list.component';
import { PaymentsInformationComponent } from './pages/payments-information/payments-information.component';
import { PersonReceiptsComponent } from './pages/person-receipts/person-receipts.component';
import { PersonRequestsComponent } from './pages/person-requests/person-requests.component';
import { RecordFormComponent } from './pages/record-form/record-form.component';
import { RecordsListComponent } from './pages/records-list/records-list.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ScannerComponent } from './pages/scanner/scanner.component';
import { StudentCourseDetailsComponent } from './pages/student-course-details/student-course-details.component';
import { StudentCoursesComponent } from './pages/student-courses/student-courses.component';
import { StudentsFormComponent } from './pages/students-form/students-form.component';
import { StudentsListComponent } from './pages/students-list/students-list.component';
import { UserAccountComponent } from './pages/user-account/user-account.component';
import { UserCredentialComponent } from './pages/user-credential/user-credential.component';
import { UserLicenseComponent } from './pages/user-license/user-license.component';
import { UserValidationComponent } from './pages/user-validation/user-validation.component';
import { ConsolePipe } from './pipes/console.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ConsolePipe,
    LoginComponent,
    ScannerComponent,
    ForgotPasswordComponent,
    CoursesListComponent,
    StudentsListComponent,
    StudentCoursesComponent,
    StudentCourseDetailsComponent,
    AddPaymentReceiptComponent,
    PaymentReceiptsListComponent,
    PaymentReceiptDetailsComponent,
    ChangeProfileRequestsComponent,
    ChangeProfileRequestsDetailsComponent,
    CredentialTypeManagerComponent,
    CoursesReportComponent,
    RegistrationComponent,
    NavigationComponent,
    CustomTableComponent,
    SectionHeaderComponent,
    FileButtonComponent,
    PaymentFeesComponent,
    FeesHistoryTableComponent,
    CoursesFormComponent,
    BackButtonComponent,
    RegistrationFormComponent,
    UserProfileFormComponent,
    StudentsFormComponent,
    CourseDetailsDialogComponent,
    EnrollmentDialogComponent,
    ImageDialogComponent,
    StudentsByStatusTableComponent,
    UserValidationComponent,
    ChangePasswordComponent,
    AlertDialogComponent,
    PersonReceiptsComponent,
    PersonRequestsComponent,
    FlowRequestDialogComponent,
    UserCredentialComponent,
    UserLicenseComponent,
    PaymentsInformationComponent,
    ContactInformationComponent,
    HomeComponent,
    StudentFileComponent,
    StudentFileDialogComponent,
    UserAccountComponent,
    ReceiptsByStatusTableComponent,
    CredentialVerticalComponent,
    CredentialHorizontalComponent,
    CredentialComponent,
    CredentialListTableComponent,
    RequestsListTableComponent,
    CredentialsListComponent,
    CoursesListDialogComponent,
    CredentialDialogComponent,
    SignatureDialogComponent,
    CredentialTypeManagerDialogComponent,
    LicenseHorizontalComponent,
    LicenseListTableComponent,
    LicenseListComponent,
    LicenseCreateDialogComponent,
    LicenseDialogComponent,
    AmateurLicenseFormComponent,
    RecordsListComponent,
    RecordDialogComponent,
    RecordFormComponent,
    RecordListTableComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatPaginatorModule,
    MatListModule,
    MatTableModule,
    // MatSortModule,
    MatChipsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    CommonModule,
    MatIconModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatDividerModule,
    MatToolbarModule,
    LayoutModule,
    MatSidenavModule,
    OverlayModule,
    MatExpansionModule,
    NgxScannerQrcodeModule,
    // Prime
    FormsModule,
    DialogModule,
    SelectModule,
    DatePickerModule,
    CheckboxModule,
    ButtonModule,
    FileUploadModule,
    SplitButtonModule,
    OrganizationChartModule,
    InputTextModule,
    CarouselModule,
    TagModule,
    ChipModule,
    CardModule,
    DataViewModule,
    TabsModule,
    ImageModule,
    TableModule,
    FloatLabelModule,
    TabViewModule,
    // custom
    TestComponent,
    CustomLottieComponent,
    CustomChartComponent,
  ],
  providers: [
    HttpClient,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
  ],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
