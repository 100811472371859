import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { Person } from 'src/app/models/person';
import { Record } from 'src/app/models/record';
import { ApiPersonsService } from 'src/app/services/api-persons.service';
import { ApiRecordsService } from 'src/app/services/api-records.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'record-dialog',
  templateUrl: './record-dialog.component.html',
  styleUrls: ['./record-dialog.component.scss'],
})
export class RecordDialogComponent implements OnInit, OnChanges {
  recordForm: UntypedFormGroup;
  personData: Person = new Person();
  record: Record;
  records: Record[] = [];

  creatingNewRecord: boolean = false;
  hasNoRecords: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; personId: number },
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    private APIRecords: ApiRecordsService,
    private APIPersons: ApiPersonsService,
    private fb: UntypedFormBuilder,
    private ui: UserInterfaceService,
    private cdr: ChangeDetectorRef,
  ) {
    this.recordForm = this.fb.group({
      type: [null, Validators.required],
      id_person: [this.data.personId, Validators.required],
      id: [null],
    });

    this.ui.spin$.next(true);
    this.recordForm.statusChanges.subscribe();

    this.recordForm.get('enabled')!.valueChanges.subscribe(value => {
      if (value === true && this.record.id === null) {
        this.recordForm.get('type')!.enable();
        this.recordForm.get('valid_until')!.enable();
      } else {
        this.recordForm.get('type')!.disable();
        this.recordForm.get('valid_until')!.disable();
      }
      this.recordForm.get('type')!.updateValueAndValidity();
      this.recordForm.get('valid_until')!.updateValueAndValidity();
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getTypes();
    this.getPerson();
    await this.getAllRecords();
    await this.getRecord();
    if (this.records.length == 0) {
      this.hasNoRecords = true;
    }
    this.recordForm.get('type')?.disable();
    this.ui.spin$.next(false);
  }

  ngOnChanges() {}

  getPerson() {
    this.APIPersons.getById(this.data.personId).subscribe(response => {
      console.log('responseGetPerson', response);
      this.personData = response.data;
    });
  }

  async getAllRecords(id_person: number = 0) {
    try {
      this.records = await firstValueFrom(
        this.APIRecords.getAll(this.data.personId),
      ).then(response => {
        console.log('responseGetAllRecords', response);
        if (response.data?.enabled)
          response.data.enabled = response.data.enabled === '1';

        this.recordForm.patchValue(response.data);
        if (response.data?.valid_until === null || !response.data) {
          this.recordForm.get('type')?.enable();
          this.recordForm
            .get('valid_until')
            ?.setValidators([Validators.required]);
          this.recordForm.get('valid_until')?.updateValueAndValidity();
        }
        return response.data;
      });
    } catch (error) {
      console.error(error);
    }
  }
  async getRecord(record_id: number = 0) {
    try {
      if (this.records !== undefined && this.records?.length > 0) {
        this.record = this.records[record_id];
      }
    } catch (error) {
      console.error(error);
    }
  }

  createNewRecord() {
    this.creatingNewRecord = true;
    this.recordForm.reset();
    this.recordForm.get('type')?.enable();
    this.recordForm.get('id_person')?.setValue(this.data.personId);
    this.recordForm.get('id')?.setValue(null);

    this.cdr.detectChanges(); // Manually trigger change detection
  }

  async getTypes() {
    this.APIRecords.getTypes().subscribe(response => {
      console.log('responseGetTypes', response);
    });
  }

  cerrar() {
    this.dialogRef.close();
  }

  save(): void {
    console.log('recordFormSaved', this.recordForm);
    //   if (this.recordForm.valid) {

    //     this.APIRecords.create(regRequest).subscribe((response) => {
    //       if (response.status == 201) {
    //         alert("Record Creado correctamente");
    //         this.dialogRef.close();
    //       } else if (response.status == 200 && response.code == 200) {
    //         alert("Record Modificado correctamente");
    //       } else if (response.status == 200 && response.code == 409) {
    //         alert(response.msg);
    //       } else {
    //         alert("Error al modificar el Record: " + response.msg);
    //       }

    //       this.ui.spin$.next(false);
    //     });
    //   } else {
    //     Object.keys(this.recordForm.controls).forEach((field) => {
    //       const control = this.recordForm.get(field);
    //       control?.markAsTouched({ onlySelf: true });
    //     });
    //   }
  }
}
