import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialType } from 'src/app/models/credential-type';

import { HttpBaseResponse } from '../models/base-response';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCredentialTypeManagerService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = 'credentialtypes';
  }

  /**
   * @ngdoc function
   * @name getAllCredentialTypes
   * @methodOf ApiChangeProfileRequestsService
   * @description Se obtienen todos los tipos de credenciales.
   * @return HttpBaseResponse
   */

  getAllCredentialTypes() {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/all`,
    );
  }

  /**
   * @ngdoc function
   * @name getCredentialType
   * @param id number
   * @methodOf ApiChangeProfileRequestsService
   * @description Se obtienen los ususarios según el estado enviado.
   * @return HttpBaseResponse
   */

  getCredentialType(code: string) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/all/${code}/`,
    );
  }

  /**
   * @ngdoc function
   * @name createNewCredentialType
   * @methodOf ApiChangeProfileRequestsService
   * @description Se crea una nueva solicitud de cambio de datos.
   * @return HttpBaseResponse
   */

  saveCredentialType(request: CredentialType & { created: boolean }) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/form`,
      request,
    );
  }
}
