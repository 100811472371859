<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  <div style="display: flex; flex-direction: column">
    <mat-form-field appearance="outline">
      <mat-label>Cursos</mat-label>
      <mat-select (valueChange)="onCourseChange($event)">
        <mat-option *ngFor="let c of courses" [value]="c.id"
          >({{ c.id }}) {{ c.name }} ({{ c.enrollments }}/{{ c.vacancies }})
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cerrar</button>
  <button
    mat-flat-button
    mat-dialog-close
    color="primary"
    (click)="goToAddPayment()"
  >
    Agregar comprobante
  </button>
</div>
