<div class="base-view">
  <section-header
    title="Cursos"
    [showNewButton]="true"
    (newButtonPressed)="new()"
  ></section-header>

  <div style="flex: 1 1 1e-9px">
    <mat-card>
      <mat-card-content>
        <custom-table
          *ngIf="dataLoaded"
          [dataSource]="allCoursesTable"
          [filterable]="true"
          [showActions]="true"
          [showEditButton]="true"
          [showDeleteButton]="true"
          (editButtonPressed)="goToEdit($event)"
          (deleteButtonPressed)="delete($event)"
        ></custom-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
