import { Component } from '@angular/core';

import { CustomChartComponent } from '../../components/custom-chart/custom-chart.component';

@Component({
  selector: 'test-component',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  imports: [CustomChartComponent],
  standalone: true,
})
export class TestComponent {
  title = 'angular-standalone';

  chartData = [50, 25, 12, 48, 90, 76, 42];
  chartLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];
  chartType = 'line';
  chartLegend = true;
  chartTitle = 'El Titulín';
}
