// src/dom/methods/hasClass.ts
function hasClass(element, className) {
  if (element) {
    if (element.classList) return element.classList.contains(className);else return new RegExp("(^| )" + className + "( |$)", "gi").test(element.className);
  }
  return false;
}

// src/dom/methods/addClass.ts
function addClass(element, className) {
  if (element && className) {
    const fn = _className => {
      if (!hasClass(element, _className)) {
        if (element.classList) element.classList.add(_className);else element.className += " " + _className;
      }
    };
    [className].flat().filter(Boolean).forEach(_classNames => _classNames.split(" ").forEach(fn));
  }
}

// src/dom/methods/calculateBodyScrollbarWidth.ts
function calculateBodyScrollbarWidth() {
  return window.innerWidth - document.documentElement.offsetWidth;
}

// src/dom/methods/getCSSVariableByRegex.ts
function getCSSVariableByRegex(variableRegex) {
  for (const sheet of document == null ? void 0 : document.styleSheets) {
    try {
      for (const rule of sheet == null ? void 0 : sheet.cssRules) {
        for (const property of rule == null ? void 0 : rule.style) {
          if (variableRegex.test(property)) {
            return {
              name: property,
              value: rule.style.getPropertyValue(property).trim()
            };
          }
        }
      }
    } catch (e) {}
  }
  return null;
}

// src/dom/helpers/blockBodyScroll.ts
function blockBodyScroll(className = "p-overflow-hidden") {
  const variableData = getCSSVariableByRegex(/-scrollbar-width$/);
  (variableData == null ? void 0 : variableData.name) && document.body.style.setProperty(variableData.name, calculateBodyScrollbarWidth() + "px");
  addClass(document.body, className);
}

// src/dom/helpers/saveAs.ts
function saveAs(file) {
  if (file) {
    let link = document.createElement("a");
    if (link.download !== void 0) {
      const {
        name,
        src
      } = file;
      link.setAttribute("href", src);
      link.setAttribute("download", name);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  }
  return false;
}

// src/dom/helpers/exportCSV.ts
function exportCSV(csv, filename) {
  let blob = new Blob([csv], {
    type: "application/csv;charset=utf-8;"
  });
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename + ".csv");
  } else {
    const isDownloaded = saveAs({
      name: filename + ".csv",
      src: URL.createObjectURL(blob)
    });
    if (!isDownloaded) {
      csv = "data:text/csv;charset=utf-8," + csv;
      window.open(encodeURI(csv));
    }
  }
}

// src/dom/methods/removeClass.ts
function removeClass(element, className) {
  if (element && className) {
    const fn = _className => {
      if (element.classList) element.classList.remove(_className);else element.className = element.className.replace(new RegExp("(^|\\b)" + _className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };
    [className].flat().filter(Boolean).forEach(_classNames => _classNames.split(" ").forEach(fn));
  }
}

// src/dom/helpers/unblockBodyScroll.ts
function unblockBodyScroll(className = "p-overflow-hidden") {
  const variableData = getCSSVariableByRegex(/-scrollbar-width$/);
  (variableData == null ? void 0 : variableData.name) && document.body.style.removeProperty(variableData.name);
  removeClass(document.body, className);
}

// src/dom/methods/getHiddenElementDimensions.ts
function getHiddenElementDimensions(element) {
  let dimensions = {
    width: 0,
    height: 0
  };
  if (element) {
    element.style.visibility = "hidden";
    element.style.display = "block";
    dimensions.width = element.offsetWidth;
    dimensions.height = element.offsetHeight;
    element.style.display = "none";
    element.style.visibility = "visible";
  }
  return dimensions;
}

// src/dom/methods/getViewport.ts
function getViewport() {
  let win = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    w = win.innerWidth || e.clientWidth || g.clientWidth,
    h = win.innerHeight || e.clientHeight || g.clientHeight;
  return {
    width: w,
    height: h
  };
}

// src/dom/methods/getWindowScrollLeft.ts
function getWindowScrollLeft() {
  let doc = document.documentElement;
  return (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
}

// src/dom/methods/getWindowScrollTop.ts
function getWindowScrollTop() {
  let doc = document.documentElement;
  return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
}

// src/dom/methods/absolutePosition.ts
function absolutePosition(element, target, gutter = true) {
  var _a, _b, _c, _d;
  if (element) {
    const elementDimensions = element.offsetParent ? {
      width: element.offsetWidth,
      height: element.offsetHeight
    } : getHiddenElementDimensions(element);
    const elementOuterHeight = elementDimensions.height;
    const elementOuterWidth = elementDimensions.width;
    const targetOuterHeight = target.offsetHeight;
    const targetOuterWidth = target.offsetWidth;
    const targetOffset = target.getBoundingClientRect();
    const windowScrollTop = getWindowScrollTop();
    const windowScrollLeft = getWindowScrollLeft();
    const viewport = getViewport();
    let top,
      left,
      origin = "top";
    if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
      top = targetOffset.top + windowScrollTop - elementOuterHeight;
      origin = "bottom";
      if (top < 0) {
        top = windowScrollTop;
      }
    } else {
      top = targetOuterHeight + targetOffset.top + windowScrollTop;
    }
    if (targetOffset.left + elementOuterWidth > viewport.width) left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth);else left = targetOffset.left + windowScrollLeft;
    element.style.top = top + "px";
    element.style.left = left + "px";
    element.style.transformOrigin = origin;
    gutter && (element.style.marginTop = origin === "bottom" ? `calc(${(_b = (_a = getCSSVariableByRegex(/-anchor-gutter$/)) == null ? void 0 : _a.value) != null ? _b : "2px"} * -1)` : (_d = (_c = getCSSVariableByRegex(/-anchor-gutter$/)) == null ? void 0 : _c.value) != null ? _d : "");
  }
}

// src/dom/methods/addStyle.ts
function addStyle(element, style) {
  if (element) {
    if (typeof style === "string") {
      element.style.cssText = style;
    } else {
      Object.entries(style || {}).forEach(([key, value]) => element.style[key] = value);
    }
  }
}

// src/dom/methods/getOuterWidth.ts
function getOuterWidth(element, margin) {
  if (element instanceof HTMLElement) {
    let width = element.offsetWidth;
    if (margin) {
      let style = getComputedStyle(element);
      width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    }
    return width;
  }
  return 0;
}

// src/dom/methods/relativePosition.ts
function relativePosition(element, target, gutter = true) {
  var _a, _b, _c, _d;
  if (element) {
    const elementDimensions = element.offsetParent ? {
      width: element.offsetWidth,
      height: element.offsetHeight
    } : getHiddenElementDimensions(element);
    const targetHeight = target.offsetHeight;
    const targetOffset = target.getBoundingClientRect();
    const viewport = getViewport();
    let top,
      left,
      origin = "top";
    if (targetOffset.top + targetHeight + elementDimensions.height > viewport.height) {
      top = -1 * elementDimensions.height;
      origin = "bottom";
      if (targetOffset.top + top < 0) {
        top = -1 * targetOffset.top;
      }
    } else {
      top = targetHeight;
    }
    if (elementDimensions.width > viewport.width) {
      left = targetOffset.left * -1;
    } else if (targetOffset.left + elementDimensions.width > viewport.width) {
      left = (targetOffset.left + elementDimensions.width - viewport.width) * -1;
    } else {
      left = 0;
    }
    element.style.top = top + "px";
    element.style.left = left + "px";
    element.style.transformOrigin = origin;
    gutter && (element.style.marginTop = origin === "bottom" ? `calc(${(_b = (_a = getCSSVariableByRegex(/-anchor-gutter$/)) == null ? void 0 : _a.value) != null ? _b : "2px"} * -1)` : (_d = (_c = getCSSVariableByRegex(/-anchor-gutter$/)) == null ? void 0 : _c.value) != null ? _d : "");
  }
}

// src/dom/methods/alignOverlay.ts
function alignOverlay(overlay, target, appendTo, calculateMinWidth = true) {
  if (overlay && target) {
    if (appendTo === "self") {
      relativePosition(overlay, target);
    } else {
      calculateMinWidth && (overlay.style.minWidth = getOuterWidth(target) + "px");
      absolutePosition(overlay, target);
    }
  }
}

// src/dom/methods/isElement.ts
function isElement(element) {
  return typeof HTMLElement === "object" ? element instanceof HTMLElement : element && typeof element === "object" && element !== null && element.nodeType === 1 && typeof element.nodeName === "string";
}

// src/dom/methods/toElement.ts
function toElement(element) {
  let target = element;
  if (element && typeof element === "object") {
    if (element.hasOwnProperty("current")) {
      target = element.current;
    } else if (element.hasOwnProperty("el")) {
      if (element.el.hasOwnProperty("nativeElement")) {
        target = element.el.nativeElement;
      } else {
        target = element.el;
      }
    }
  }
  return isElement(target) ? target : void 0;
}

// src/dom/methods/appendChild.ts
function appendChild(element, child) {
  const target = toElement(element);
  if (target) target.appendChild(child);else throw new Error("Cannot append " + child + " to " + element);
}

// src/dom/methods/calculateScrollbarHeight.ts
var calculatedScrollbarHeight = void 0;
function calculateScrollbarHeight(element) {
  if (element) {
    let style = getComputedStyle(element);
    return element.offsetHeight - element.clientHeight - parseFloat(style.borderTopWidth) - parseFloat(style.borderBottomWidth);
  } else {
    if (calculatedScrollbarHeight != null) return calculatedScrollbarHeight;
    let scrollDiv = document.createElement("div");
    addStyle(scrollDiv, {
      width: "100px",
      height: "100px",
      overflow: "scroll",
      position: "absolute",
      top: "-9999px"
    });
    document.body.appendChild(scrollDiv);
    let scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;
    document.body.removeChild(scrollDiv);
    calculatedScrollbarHeight = scrollbarHeight;
    return scrollbarHeight;
  }
}

// src/dom/methods/calculateScrollbarWidth.ts
var calculatedScrollbarWidth = void 0;
function calculateScrollbarWidth(element) {
  if (element) {
    let style = getComputedStyle(element);
    return element.offsetWidth - element.clientWidth - parseFloat(style.borderLeftWidth) - parseFloat(style.borderRightWidth);
  } else {
    if (calculatedScrollbarWidth != null) return calculatedScrollbarWidth;
    let scrollDiv = document.createElement("div");
    addStyle(scrollDiv, {
      width: "100px",
      height: "100px",
      overflow: "scroll",
      position: "absolute",
      top: "-9999px"
    });
    document.body.appendChild(scrollDiv);
    let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    calculatedScrollbarWidth = scrollbarWidth;
    return scrollbarWidth;
  }
}

// src/dom/methods/clearSelection.ts
function clearSelection() {
  if (window.getSelection) {
    const selection = window.getSelection() || {};
    if (selection.empty) {
      selection.empty();
    } else if (selection.removeAllRanges && selection.rangeCount > 0 && selection.getRangeAt(0).getClientRects().length > 0) {
      selection.removeAllRanges();
    }
  }
}

// src/dom/methods/setAttributes.ts
function setAttributes(element, attributes = {}) {
  if (isElement(element)) {
    const computedStyles = (rule, value) => {
      var _a, _b;
      const styles = ((_a = element == null ? void 0 : element.$attrs) == null ? void 0 : _a[rule]) ? [(_b = element == null ? void 0 : element.$attrs) == null ? void 0 : _b[rule]] : [];
      return [value].flat().reduce((cv, v) => {
        if (v !== null && v !== void 0) {
          const type = typeof v;
          if (type === "string" || type === "number") {
            cv.push(v);
          } else if (type === "object") {
            const _cv = Array.isArray(v) ? computedStyles(rule, v) : Object.entries(v).map(([_k, _v]) => rule === "style" && (!!_v || _v === 0) ? `${_k.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()}:${_v}` : !!_v ? _k : void 0);
            cv = _cv.length ? cv.concat(_cv.filter(c => !!c)) : cv;
          }
        }
        return cv;
      }, styles);
    };
    Object.entries(attributes).forEach(([key, value]) => {
      if (value !== void 0 && value !== null) {
        const matchedEvent = key.match(/^on(.+)/);
        if (matchedEvent) {
          element.addEventListener(matchedEvent[1].toLowerCase(), value);
        } else if (key === "p-bind") {
          setAttributes(element, value);
        } else {
          value = key === "class" ? [...new Set(computedStyles("class", value))].join(" ").trim() : key === "style" ? computedStyles("style", value).join(";").trim() : value;
          (element.$attrs = element.$attrs || {}) && (element.$attrs[key] = value);
          element.setAttribute(key, value);
        }
      }
    });
  }
}

// src/dom/methods/createElement.ts
function createElement(type, attributes = {}, ...children) {
  if (type) {
    const element = document.createElement(type);
    setAttributes(element, attributes);
    element.append(...children);
    return element;
  }
  return void 0;
}

// src/dom/methods/createStyleAsString.ts
function createStyleAsString(css, options = {}) {
  return css ? `'<style ${Object.entries(options).reduce((s, [k, v]) => s + `${k}="${v}"`, " ")}>${css}</style>'` : "";
}

// src/dom/methods/createStyleTag.ts
function createStyleTag(attributes = {}, container) {
  let element = document.createElement("style");
  setAttributes(element, attributes);
  if (!container) {
    container = document.head;
  }
  container.appendChild(element);
  return element;
}

// src/dom/methods/fadeIn.ts
function fadeIn(element, duration) {
  if (element) {
    element.style.opacity = "0";
    let last = + /* @__PURE__ */new Date();
    let opacity = "0";
    let tick = function () {
      opacity = `${+element.style.opacity + (( /* @__PURE__ */new Date()).getTime() - last) / duration}`;
      element.style.opacity = opacity;
      last = + /* @__PURE__ */new Date();
      if (+opacity < 1) {
        !!window.requestAnimationFrame && requestAnimationFrame(tick) || setTimeout(tick, 16);
      }
    };
    tick();
  }
}

// src/dom/methods/fadeOut.ts
function fadeOut(element, duration) {
  if (element) {
    let opacity = 1,
      interval = 50,
      gap = interval / duration;
    let fading = setInterval(() => {
      opacity -= gap;
      if (opacity <= 0) {
        opacity = 0;
        clearInterval(fading);
      }
      element.style.opacity = opacity.toString();
    }, interval);
  }
}

// src/dom/methods/find.ts
function find(element, selector) {
  return isElement(element) ? Array.from(element.querySelectorAll(selector)) : [];
}

// src/dom/methods/findSingle.ts
function findSingle(element, selector) {
  return isElement(element) ? element.matches(selector) ? element : element.querySelector(selector) : null;
}

// src/dom/methods/focus.ts
function focus(element, options) {
  element && document.activeElement !== element && element.focus(options);
}

// src/dom/methods/getAttribute.ts
function getAttribute(element, name) {
  if (isElement(element)) {
    const value = element.getAttribute(name);
    if (!isNaN(value)) {
      return +value;
    }
    if (value === "true" || value === "false") {
      return value === "true";
    }
    return value;
  }
  return void 0;
}

// src/dom/methods/resolveUserAgent.ts
function resolveUserAgent() {
  let ua = navigator.userAgent.toLowerCase();
  let match = /(chrome)[ ]([\w.]+)/.exec(ua) || /(webkit)[ ]([\w.]+)/.exec(ua) || /(opera)(?:.*version|)[ ]([\w.]+)/.exec(ua) || /(msie) ([\w.]+)/.exec(ua) || ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) || [];
  return {
    browser: match[1] || "",
    version: match[2] || "0"
  };
}

// src/dom/methods/getBrowser.ts
var browser = null;
function getBrowser() {
  if (!browser) {
    browser = {};
    let matched = resolveUserAgent();
    if (matched.browser) {
      browser[matched.browser] = true;
      browser["version"] = matched.version;
    }
    if (browser["chrome"]) {
      browser["webkit"] = true;
    } else if (browser["webkit"]) {
      browser["safari"] = true;
    }
  }
  return browser;
}

// src/dom/methods/getBrowserLanguage.ts
function getBrowserLanguage() {
  return navigator.languages && navigator.languages.length && navigator.languages[0] || navigator.language || "en";
}

// src/dom/methods/getCursorOffset.ts
function getCursorOffset(element, prevText, nextText, currentText) {
  if (element) {
    let style = getComputedStyle(element);
    let ghostDiv = document.createElement("div");
    ghostDiv.style.position = "absolute";
    ghostDiv.style.top = "0px";
    ghostDiv.style.left = "0px";
    ghostDiv.style.visibility = "hidden";
    ghostDiv.style.pointerEvents = "none";
    ghostDiv.style.overflow = style.overflow;
    ghostDiv.style.width = style.width;
    ghostDiv.style.height = style.height;
    ghostDiv.style.padding = style.padding;
    ghostDiv.style.border = style.border;
    ghostDiv.style.overflowWrap = style.overflowWrap;
    ghostDiv.style.whiteSpace = style.whiteSpace;
    ghostDiv.style.lineHeight = style.lineHeight;
    ghostDiv.innerHTML = prevText.replace(/\r\n|\r|\n/g, "<br />");
    let ghostSpan = document.createElement("span");
    ghostSpan.textContent = currentText;
    ghostDiv.appendChild(ghostSpan);
    let text = document.createTextNode(nextText);
    ghostDiv.appendChild(text);
    document.body.appendChild(ghostDiv);
    const {
      offsetLeft,
      offsetTop,
      clientHeight
    } = ghostSpan;
    document.body.removeChild(ghostDiv);
    return {
      left: Math.abs(offsetLeft - element.scrollLeft),
      top: Math.abs(offsetTop - element.scrollTop) + clientHeight
    };
  }
  return {
    top: "auto",
    left: "auto"
  };
}

// src/dom/methods/getFocusableElements.ts
function getFocusableElements(element, selector = "") {
  let focusableElements = find(element, `button:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            [href][clientHeight][clientWidth]:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            input:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            select:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            textarea:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            [tabIndex]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            [contenteditable]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector}`);
  let visibleFocusableElements = [];
  for (let focusableElement of focusableElements) {
    if (getComputedStyle(focusableElement).display != "none" && getComputedStyle(focusableElement).visibility != "hidden") visibleFocusableElements.push(focusableElement);
  }
  return visibleFocusableElements;
}

// src/dom/methods/getFirstFocusableElement.ts
function getFirstFocusableElement(element, selector) {
  const focusableElements = getFocusableElements(element, selector);
  return focusableElements.length > 0 ? focusableElements[0] : null;
}

// src/dom/methods/getHeight.ts
function getHeight(element) {
  if (element) {
    let height = element.offsetHeight;
    let style = getComputedStyle(element);
    height -= parseFloat(style.paddingTop) + parseFloat(style.paddingBottom) + parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
    return height;
  }
  return 0;
}

// src/dom/methods/getHiddenElementOuterHeight.ts
function getHiddenElementOuterHeight(element) {
  if (element) {
    element.style.visibility = "hidden";
    element.style.display = "block";
    let elementHeight = element.offsetHeight;
    element.style.display = "none";
    element.style.visibility = "visible";
    return elementHeight;
  }
  return 0;
}

// src/dom/methods/getHiddenElementOuterWidth.ts
function getHiddenElementOuterWidth(element) {
  if (element) {
    element.style.visibility = "hidden";
    element.style.display = "block";
    let elementWidth = element.offsetWidth;
    element.style.display = "none";
    element.style.visibility = "visible";
    return elementWidth;
  }
  return 0;
}

// src/dom/methods/getParentNode.ts
function getParentNode(element) {
  if (element) {
    let parent = element.parentNode;
    if (parent && parent instanceof ShadowRoot && parent.host) {
      parent = parent.host;
    }
    return parent;
  }
  return null;
}

// src/dom/methods/getIndex.ts
function getIndex(element) {
  var _a;
  if (element) {
    let children = (_a = getParentNode(element)) == null ? void 0 : _a.childNodes;
    let num = 0;
    if (children) {
      for (let i = 0; i < children.length; i++) {
        if (children[i] === element) return num;
        if (children[i].nodeType === 1) num++;
      }
    }
  }
  return -1;
}

// src/dom/methods/getInnerWidth.ts
function getInnerWidth(element) {
  if (element) {
    let width = element.offsetWidth;
    let style = getComputedStyle(element);
    width -= parseFloat(style.borderLeft) + parseFloat(style.borderRight);
    return width;
  }
  return 0;
}

// src/dom/methods/getLastFocusableElement.ts
function getLastFocusableElement(element, selector) {
  const focusableElements = getFocusableElements(element, selector);
  return focusableElements.length > 0 ? focusableElements[focusableElements.length - 1] : null;
}

// src/dom/methods/getNextElementSibling.ts
function getNextElementSibling(element, selector) {
  let nextElement = element.nextElementSibling;
  while (nextElement) {
    if (nextElement.matches(selector)) {
      return nextElement;
    } else {
      nextElement = nextElement.nextElementSibling;
    }
  }
  return null;
}

// src/dom/methods/getNextFocusableElement.ts
function getNextFocusableElement(container, element, selector) {
  const focusableElements = getFocusableElements(container, selector);
  const index = focusableElements.length > 0 ? focusableElements.findIndex(el => el === element) : -1;
  const nextIndex = index > -1 && focusableElements.length >= index + 1 ? index + 1 : -1;
  return nextIndex > -1 ? focusableElements[nextIndex] : null;
}

// src/dom/methods/getOffset.ts
function getOffset(element) {
  if (element) {
    let rect = element.getBoundingClientRect();
    return {
      top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
      left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0)
    };
  }
  return {
    top: "auto",
    left: "auto"
  };
}

// src/dom/methods/getOuterHeight.ts
function getOuterHeight(element, margin) {
  if (element) {
    let height = element.offsetHeight;
    if (margin) {
      let style = getComputedStyle(element);
      height += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
    }
    return height;
  }
  return 0;
}

// src/dom/methods/getParents.ts
function getParents(element, parents = []) {
  const parent = getParentNode(element);
  return parent === null ? parents : getParents(parent, parents.concat([parent]));
}

// src/dom/methods/getPreviousElementSibling.ts
function getPreviousElementSibling(element, selector) {
  let previousElement = element.previousElementSibling;
  while (previousElement) {
    if (previousElement.matches(selector)) {
      return previousElement;
    } else {
      previousElement = previousElement.previousElementSibling;
    }
  }
  return null;
}

// src/dom/methods/getScrollableParents.ts
function getScrollableParents(element) {
  let scrollableParents = [];
  if (element) {
    let parents = getParents(element);
    const overflowRegex = /(auto|scroll)/;
    const overflowCheck = node => {
      try {
        let styleDeclaration = window["getComputedStyle"](node, null);
        return overflowRegex.test(styleDeclaration.getPropertyValue("overflow")) || overflowRegex.test(styleDeclaration.getPropertyValue("overflowX")) || overflowRegex.test(styleDeclaration.getPropertyValue("overflowY"));
      } catch (err) {
        return false;
      }
    };
    for (let parent of parents) {
      let scrollSelectors = parent.nodeType === 1 && parent.dataset["scrollselectors"];
      if (scrollSelectors) {
        let selectors = scrollSelectors.split(",");
        for (let selector of selectors) {
          let el = findSingle(parent, selector);
          if (el && overflowCheck(el)) {
            scrollableParents.push(el);
          }
        }
      }
      if (parent.nodeType !== 9 && overflowCheck(parent)) {
        scrollableParents.push(parent);
      }
    }
  }
  return scrollableParents;
}

// src/dom/methods/getSelection.ts
function getSelection() {
  if (window.getSelection) return window.getSelection().toString();else if (document.getSelection) return document.getSelection().toString();
  return void 0;
}

// src/dom/methods/isExist.ts
function isExist(element) {
  return !!(element !== null && typeof element !== "undefined" && element.nodeName && getParentNode(element));
}

// src/dom/methods/getTargetElement.ts
function getTargetElement(target, currentElement) {
  var _a;
  if (!target) return void 0;
  switch (target) {
    case "document":
      return document;
    case "window":
      return window;
    case "@next":
      return currentElement == null ? void 0 : currentElement.nextElementSibling;
    case "@prev":
      return currentElement == null ? void 0 : currentElement.previousElementSibling;
    case "@parent":
      return currentElement == null ? void 0 : currentElement.parentElement;
    case "@grandparent":
      return (_a = currentElement == null ? void 0 : currentElement.parentElement) == null ? void 0 : _a.parentElement;
    default:
      if (typeof target === "string") {
        return document.querySelector(target);
      }
      const isFunction = obj => !!(obj && obj.constructor && obj.call && obj.apply);
      const element = toElement(isFunction(target) ? target() : target);
      return (element == null ? void 0 : element.nodeType) === 9 || isExist(element) ? element : void 0;
  }
}

// src/dom/methods/getUserAgent.ts
function getUserAgent() {
  return navigator.userAgent;
}

// src/dom/methods/getWidth.ts
function getWidth(element) {
  if (element) {
    let width = element.offsetWidth;
    let style = getComputedStyle(element);
    width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight) + parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
    return width;
  }
  return 0;
}

// src/dom/methods/hasCSSAnimation.ts
function hasCSSAnimation(element) {
  if (element) {
    const style = getComputedStyle(element);
    const animationDuration = parseFloat(style.getPropertyValue("animation-duration") || "0");
    return animationDuration > 0;
  }
  return false;
}

// src/dom/methods/hasCSSTransition.ts
function hasCSSTransition(element) {
  if (element) {
    const style = getComputedStyle(element);
    const transitionDuration = parseFloat(style.getPropertyValue("transition-duration") || "0");
    return transitionDuration > 0;
  }
  return false;
}

// src/dom/methods/invokeElementMethod.ts
function invokeElementMethod(element, methodName, args) {
  element[methodName].apply(element, args);
}

// src/dom/methods/isAndroid.ts
function isAndroid() {
  return /(android)/i.test(navigator.userAgent);
}

// src/dom/methods/isAttributeEquals.ts
function isAttributeEquals(element, name, value) {
  return isElement(element) ? getAttribute(element, name) === value : false;
}

// src/dom/methods/isAttributeNotEquals.ts
function isAttributeNotEquals(element, name, value) {
  return !isAttributeEquals(element, name, value);
}

// src/dom/methods/isClickable.ts
function isClickable(element) {
  if (element) {
    const targetNode = element.nodeName;
    const parentNode = element.parentElement && element.parentElement.nodeName;
    return targetNode === "INPUT" || targetNode === "TEXTAREA" || targetNode === "BUTTON" || targetNode === "A" || parentNode === "INPUT" || parentNode === "TEXTAREA" || parentNode === "BUTTON" || parentNode === "A" || !!element.closest(".p-button, .p-checkbox, .p-radiobutton");
  }
  return false;
}

// src/dom/methods/isClient.ts
function isClient() {
  return !!(typeof window !== "undefined" && window.document && window.document.createElement);
}

// src/dom/methods/isFocusableElement.ts
function isFocusableElement(element, selector = "") {
  return isElement(element) ? element.matches(`button:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            [href][clientHeight][clientWidth]:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            input:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            select:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            textarea:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            [tabIndex]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
            [contenteditable]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector}`) : false;
}

// src/dom/methods/isVisible.ts
function isVisible(element) {
  return !!(element && element.offsetParent != null);
}

// src/dom/methods/isHidden.ts
function isHidden(element) {
  return !isVisible(element);
}

// src/dom/methods/isIOS.ts
function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window["MSStream"];
}

// src/dom/methods/isServer.ts
function isServer() {
  return !isClient();
}

// src/dom/methods/isTouchDevice.ts
function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

// src/dom/methods/nestedPosition.ts
function nestedPosition(element, level) {
  var _a;
  if (element) {
    const parentItem = element.parentElement;
    const elementOffset = getOffset(parentItem);
    const viewport = getViewport();
    const sublistWidth = element.offsetParent ? element.offsetWidth : getHiddenElementOuterWidth(element);
    const itemOuterWidth = getOuterWidth((_a = parentItem == null ? void 0 : parentItem.children) == null ? void 0 : _a[0]);
    let left = "";
    if (elementOffset.left + itemOuterWidth + sublistWidth > viewport.width - calculateScrollbarWidth()) {
      if (elementOffset.left < sublistWidth) {
        if (level % 2 === 1) {
          left = elementOffset.left ? "-" + elementOffset.left + "px" : "100%";
        } else if (level % 2 === 0) {
          left = viewport.width - sublistWidth - calculateScrollbarWidth() + "px";
        }
      } else {
        left = "-100%";
      }
    } else {
      left = "100%";
    }
    element.style.top = "0px";
    element.style.left = left;
  }
}

// src/dom/methods/remove.ts
function remove(element) {
  var _a;
  if (element) {
    if (!("remove" in Element.prototype)) (_a = element.parentNode) == null ? void 0 : _a.removeChild(element);else element.remove();
  }
}

// src/dom/methods/removeChild.ts
function removeChild(element, child) {
  const target = toElement(element);
  if (target) target.removeChild(child);else throw new Error("Cannot remove " + child + " from " + element);
}

// src/dom/methods/removeStyleTag.ts
function removeStyleTag(element) {
  var _a;
  if (isExist(element)) {
    try {
      (_a = element.parentNode) == null ? void 0 : _a.removeChild(element);
    } catch (error) {}
    return null;
  }
  return element;
}

// src/dom/methods/scrollInView.ts
function scrollInView(container, item) {
  let borderTopValue = getComputedStyle(container).getPropertyValue("borderTopWidth");
  let borderTop = borderTopValue ? parseFloat(borderTopValue) : 0;
  let paddingTopValue = getComputedStyle(container).getPropertyValue("paddingTop");
  let paddingTop = paddingTopValue ? parseFloat(paddingTopValue) : 0;
  let containerRect = container.getBoundingClientRect();
  let itemRect = item.getBoundingClientRect();
  let offset = itemRect.top + document.body.scrollTop - (containerRect.top + document.body.scrollTop) - borderTop - paddingTop;
  let scroll = container.scrollTop;
  let elementHeight = container.clientHeight;
  let itemHeight = getOuterHeight(item);
  if (offset < 0) {
    container.scrollTop = scroll + offset;
  } else if (offset + itemHeight > elementHeight) {
    container.scrollTop = scroll + offset - elementHeight + itemHeight;
  }
}

// src/dom/methods/setAttribute.ts
function setAttribute(element, attribute = "", value) {
  if (isElement(element) && value !== null && value !== void 0) {
    element.setAttribute(attribute, value);
  }
}
export { absolutePosition, addClass, addStyle, alignOverlay, appendChild, blockBodyScroll, calculateBodyScrollbarWidth, calculateScrollbarHeight, calculateScrollbarWidth, clearSelection, createElement, createStyleAsString, createStyleTag, exportCSV, fadeIn, fadeOut, find, findSingle, focus, getAttribute, getBrowser, getBrowserLanguage, getCSSVariableByRegex, getCursorOffset, getFirstFocusableElement, getFocusableElements, getHeight, getHiddenElementDimensions, getHiddenElementOuterHeight, getHiddenElementOuterWidth, getIndex, getInnerWidth, getLastFocusableElement, getNextElementSibling, getNextFocusableElement, getOffset, getOuterHeight, getOuterWidth, getParentNode, getParents, getPreviousElementSibling, getScrollableParents, getSelection, getTargetElement, getUserAgent, getViewport, getWidth, getWindowScrollLeft, getWindowScrollTop, hasCSSAnimation, hasCSSTransition, hasClass, invokeElementMethod, isAndroid, isAttributeEquals, isAttributeNotEquals, isClickable, isClient, isElement, isExist, isFocusableElement, isHidden, isIOS, isServer, isTouchDevice, isVisible, nestedPosition, relativePosition, remove, removeChild, removeClass, removeStyleTag, resolveUserAgent, saveAs, scrollInView, setAttribute, setAttributes, toElement, unblockBodyScroll };
