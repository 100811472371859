<mat-toolbar
  color="primary"
  *ngIf="!isScanner && (isLoggedIn$ | async) && showNav"
>
  <button
    class="!text-white"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="drawer.toggle()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <img
    src="assets/images/logoFooterFAB.webp"
    alt="FAB"
    (click)="goHome()"
    style="height: 35px; padding-left: 10px; cursor: pointer"
  />
  <span class="flex-auto"></span>
  <label class="overflow-hidden text-xl text-white">
    {{ loggedUserName }}
  </label>
  <span *ngIf="useLegajo$" class="mx-1 mt-1 text-sm text-white">
    ({{ (legajoId$ | async) || 'Staff' }})
  </span>
  <button class="!text-white" mat-icon-button (click)="logout()">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    opened
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="
      (isLoggedIn$ | async) === true &&
      (isHandset$ | async) === false &&
      !isScanner &&
      showNav
    "
  >
    <mat-list>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="student-courses"
      >
        <mat-icon matListItemIcon>school</mat-icon>
        <div matListItemTitle>Mis Cursos</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="person-receipts-list"
      >
        <mat-icon matListItemIcon>receipt</mat-icon>
        <div matListItemTitle>Mis Comprobantes</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="person-requests-list"
      >
        <mat-icon matListItemIcon>how_to_reg</mat-icon>
        <div matListItemTitle>Mis Solicitudes</div>
      </mat-list-item>
      <mat-divider
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
      ></mat-divider>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="user-account"
      >
        <mat-icon matListItemIcon>account_circle</mat-icon>
        <div matListItemTitle>Mis Datos</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="payments-information"
      >
        <mat-icon matListItemIcon>credit_card</mat-icon>
        <div matListItemTitle>Medios de Pago</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="contact-information"
      >
        <mat-icon matListItemIcon>phone</mat-icon>
        <div matListItemTitle>Datos de contacto</div>
      </mat-list-item>
      <mat-divider
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.USER])"
      ></mat-divider>
      <mat-list-item
        *ngIf="
          showMenuByProfile([userProfiles.ADMIN]) ||
          ((useCredential$ | async) && showMenuByProfile([userProfiles.USER]))
        "
        (click)="openNewTab('user-credential')"
        target="_blank"
      >
        <mat-icon matListItemIcon>contact_mail</mat-icon>
        <div matListItemTitle>Credenciales</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="
          showMenuByProfile([userProfiles.ADMIN]) ||
          ((useLicense$ | async) && showMenuByProfile([userProfiles.USER]))
        "
        (click)="openNewTab('user-license')"
        target="_blank"
      >
        <mat-icon matListItemIcon>sports_mma</mat-icon>
        <div matListItemTitle>Licencias</div>
      </mat-list-item>
      <mat-divider
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.TREASURY,
            userProfiles.SCHOOL,
            userProfiles.SECRETARY,
            userProfiles.CREDENTIAL,
            userProfiles.LICENSE,
          ])
        "
      ></mat-divider>
      <mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.TREASURY,
            userProfiles.SCHOOL,
            userProfiles.SECRETARY,
            userProfiles.CREDENTIAL,
            userProfiles.LICENSE,
          ])
        "
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="students-list"
      >
        <mat-icon matListItemIcon>people</mat-icon>
        <div matListItemTitle>Usuarios</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.SCHOOL,
            userProfiles.SECRETARY,
          ])
        "
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="change-profile-requests"
      >
        <mat-icon matListItemIcon>how_to_reg</mat-icon>
        <div matListItemTitle>Solicitudes</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.SCHOOL])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="courses-list"
      >
        <mat-icon matListItemIcon>book</mat-icon>
        <div matListItemTitle>Cursos</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.TREASURY])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="payment-receipts-list"
      >
        <mat-icon matListItemIcon>receipt</mat-icon>
        <div matListItemTitle>Comprobantes</div>
      </mat-list-item>
      <mat-divider
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.SECRETARY])"
      ></mat-divider>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.SECRETARY])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="credential-type-manager"
      >
        <mat-icon matListItemIcon>build</mat-icon>
        <div matListItemTitle>Gestión de cargos</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.SECRETARY,
            userProfiles.CREDENTIAL,
          ])
        "
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="credentials-list"
      >
        <mat-icon matListItemIcon>assignment_ind</mat-icon>
        <div matListItemTitle>Gestión de Credenciales</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.SECRETARY,
            userProfiles.LICENSE,
          ])
        "
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="licenses-list"
      >
        <mat-icon matListItemIcon>sports_mma</mat-icon>
        <div matListItemTitle>Gestión de Licencias</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.RECORDS])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="record-list"
      >
        <mat-icon matListItemIcon>military_tech</mat-icon>
        <div matListItemTitle>Gestión de Récords</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.FISCAL])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="fiscal-list"
      >
        <mat-icon matListItemIcon>gavel</mat-icon>
        <div matListItemTitle>Fiscales</div>
      </mat-list-item>
      <mat-list-item
        *ngIf="showMenuByProfile([userProfiles.ADMIN, userProfiles.MEDIC])"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="medic-list"
      >
        <mat-icon matListItemIcon>local_hospital</mat-icon>
        <div matListItemTitle>Gestión Médica</div>
      </mat-list-item>
      <mat-divider
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.TREASURY,
            userProfiles.SCHOOL,
          ])
        "
      ></mat-divider>
      <mat-list-item
        *ngIf="
          showMenuByProfile([
            userProfiles.ADMIN,
            userProfiles.TREASURY,
            userProfiles.SCHOOL,
          ])
        "
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
        routerLink="courses-report"
      >
        <mat-icon matListItemIcon>summarize</mat-icon>
        <div matListItemTitle>Reporte por curso</div>
      </mat-list-item>
      <mat-list-item>
        <button
          mat-button
          color="primary"
          (click)="toggleDarkMode()"
          class="w-full dark:!text-surface-400"
        >
          <mat-icon>{{ isDarkMode ? 'light_mode' : 'dark_mode' }}</mat-icon>
          {{ isDarkMode ? 'Modo Claro' : 'Modo Oscuro' }}
        </button>
      </mat-list-item>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
