import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PaymentStatusEnum } from 'src/app/enums/payment-status-enum';
import { FeesHistory } from 'src/app/models/fees-history';
import { ReceiptsHistory } from 'src/app/models/receipts-history';
import { ApiPaymentsService } from 'src/app/services/api-payments.service';
import { IdentityService } from 'src/app/services/identity.service';

import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

@Component({
  selector: 'fees-history-table',
  templateUrl: './fees-history-table.component.html',
  styleUrls: ['./fees-history-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class FeesHistoryTableComponent implements OnInit, AfterViewInit {
  @Input() courseId: number;
  @Input() personId: number;

  @Output() hasReceiptsToApprove: EventEmitter<boolean> = new EventEmitter(
    false,
  );

  columnsToDisplay = ['fee_number', 'id_status', 'total_amount'];
  expandedColums = [
    'id_receipt',
    'receipt_date',
    'status_name',
    'receipt_amount',
    'payment_type',
    'comments',
    'answer',
    'image',
  ];
  expandedElement: FeesHistory[] | null;

  feesHistory: FeesHistory[] = [];

  paymentStatus: typeof PaymentStatusEnum = PaymentStatusEnum;

  constructor(
    private APIPayments: ApiPaymentsService,
    private route: ActivatedRoute,
    private identity: IdentityService,
    private imageDialog: MatDialog,
  ) {
    // this.route.params.subscribe(params => {
    //   this.courseId = params['courseId'];
    //   this.personId = this.identity.getDecodedToken().id_person;
    // });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.APIPayments.getPaymentsHistory(this.courseId, this.personId).subscribe(
      response => {
        this.feesHistory = response.data.fees;

        this.feesHistory.forEach(fee => {
          const receipts = response.data.receipts;

          fee.receipts = receipts.filter(
            (r: ReceiptsHistory) => r.id_payment == fee.id,
          );

          // VALIDO SI TENGO COMPROBANTES EN APROBACION Y HAGO EL EVENT EMITTER
          const receiptsToApprove = fee.receipts.filter(
            r => r.id_status == null,
          );
          if (receiptsToApprove.length > 0)
            this.hasReceiptsToApprove.emit(true);
        });

        this.expandedElement = this.feesHistory;
      },
    );
  }

  showImageDialog(receiptId: number, url: string, payment_type: string): void {
    this.imageDialog.open(ImageDialogComponent, {
      data: {
        title: 'Comprobante #' + receiptId,
        url: url,
        payment_type: payment_type,
      },
    });
  }
}
