import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-students-form',
  templateUrl: './students-form.component.html',
  styleUrls: ['./students-form.component.scss'],
})
export class StudentsFormComponent implements OnInit {
  title: string = 'Datos del Alumno';

  personId: number = 0;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if (params['personId']) {
        this.personId = params['personId'];
      }
    });
  }

  ngOnInit(): void {}
}
