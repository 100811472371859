<div class="base-view">
  <section-header
    title="Records"
    [showNewButton]="true"
    [newButtonText]="'Nuevo Record'"
    (newButtonPressed)="newRecord()"
  >
  </section-header>

  <mat-card *ngIf="recordTypeList.length">
    <mat-tab-group animationDuration="250ms">
      <mat-tab label="Legajos">
        <record-list-table [recordType]="'all'"></record-list-table>
      </mat-tab>
      <div *ngFor="let recordType of recordTypeList">
        <mat-tab label="{{ recordType.name }}">
          <record-list-table [recordType]="recordType.code"></record-list-table>
        </mat-tab>
      </div>
    </mat-tab-group>
  </mat-card>
</div>
