import { Component } from '@angular/core';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  username: string = '';

  constructor(private identityService: IdentityService) {
    const userLoged = this.identityService.getDecodedToken();
    if (userLoged.user_firstname) {
      this.username =
        userLoged.user_firstname[0].toUpperCase() +
        userLoged.user_firstname.substring(1);
    } else if (userLoged.role_name) {
      this.username =
        userLoged.role_name[0].toUpperCase() + userLoged.role_name.substring(1);
    }
  }
}
