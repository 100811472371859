<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content [formGroup]="enrollmentForm">
  <div class="form-control">
    <div>
      <mat-form-field
        appearance="outline"
        class="form-field"
        style="margin: 20px 0px 0px 20px"
      >
        <mat-label>Nombre</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-error *ngIf="enrollmentForm.get('name')?.hasError('required')">
          Este campo es <strong>obligatorio.</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="form-field"
        style="margin: 20px 0px 0px 20px"
      >
        <mat-label>Codigo</mat-label>
        <input matInput type="text" formControlName="code" />
      </mat-form-field>
    </div>
    <div class="group-gap">
      <h5 class="mb-3">Estado:</h5>
      <div class="d-block my-3">
        <div class="custom-control custom-radio">
          <input
            id="yes"
            type="radio"
            class="custom-control-input"
            value="yes"
            [checked]="enrollmentForm.get('enabled')?.value"
            (change)="handleEnabled($event)"
          />
          <label class="custom-control-label" style="font-size: 17px" for="yes"
            >Activo</label
          >
        </div>

        <div class="custom-control custom-radio">
          <input
            id="no"
            type="radio"
            class="custom-control-input"
            value="no"
            [checked]="!enrollmentForm.get('enabled')?.value"
            (change)="handleEnabled($event)"
          />
          <label class="custom-control-label" style="font-size: 17px" for="no"
            >Inactivo</label
          >
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end" style="margin-bottom: 0">
    <button mat-stroked-button mat-dialog-close>Cerrar</button>
    <button mat-flat-button color="primary" (click)="save()">Guardar</button>
  </div>
</div>
