<div style="flex: 1 1 1e-9px">
  <div
    *ngIf="
      !allPersonListTableStructure.data?.length &&
      !credentialListTableStructure.data?.length
    "
  >
    <mat-spinner></mat-spinner>
  </div>
  <mat-card
    *ngIf="
      !(
        !allPersonListTableStructure.data?.length &&
        !credentialListTableStructure.data?.length
      )
    "
  >
    <mat-card-content>
      <custom-table
        *ngIf="
          allPersonListTableStructure.data?.length && credentialType === 'all'
        "
        [dataSource]="allPersonListTableStructure"
        [filterable]="true"
        [showActions]="true"
        [showEditButton]="false"
        (editButtonPressed)="openEditCredentialDialog($event)"
        [showDeleteButton]="false"
        (deleteButtonPressed)="delete($event)"
        [showDetailsButton]="false"
        [showGenericButton2]="true"
        [genericButton2]="createCredentialButton"
        [genericButton2Title]="createCredentialButton.title || 'Crear'"
        (genericButton2Pressed)="openCreateCredentialDialog($event.id_person)"
      ></custom-table>
      <p
        *ngIf="
          !(
            !allPersonListTableStructure.data?.length &&
            !credentialListTableStructure.data?.length
          ) &&
          !allPersonListTableStructure.data?.length &&
          credentialType === 'all'
        "
      >
        No hay Legajos que mostrar
      </p>
      <custom-table
        *ngIf="
          credentialListTableStructure.data?.length && credentialType !== 'all'
        "
        [dataSource]="credentialListTableStructure"
        [filterable]="true"
        [showActions]="true"
        [showEditButton]="true"
        (editButtonPressed)="openEditCredentialDialog($event)"
        [showDeleteButton]="false"
        (deleteButtonPressed)="delete($event)"
        [showDetailsButton]="false"
        [showGenericButton2]="false"
      >
      </custom-table>
      <p
        *ngIf="
          !credentialListTableStructure.data?.length && credentialType !== 'all'
        "
      >
        No hay Credenciales de {{ credentialType }} que mostrar
      </p>
    </mat-card-content>
  </mat-card>
</div>
