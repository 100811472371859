import { Component, OnInit } from '@angular/core';
// ! UPDATE ANGULAR 13 TO 15
// import { MatDialog } from '@angular/material/dialog';
// import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { CustomTableGenericButton } from 'src/app/components/custom-table/interfaces/custom-table-generic-button';
import { CustomTableStructure } from 'src/app/components/custom-table/interfaces/custom-table-structure';
import { ImageDialogComponent } from 'src/app/components/image-dialog/image-dialog.component';
import { PersonReceiptsListItem } from 'src/app/models/person-receipts-list-item';
import { ApiPaymentsService } from 'src/app/services/api-payments.service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-person-receipts',
  templateUrl: './person-receipts.component.html',
  styleUrls: ['./person-receipts.component.scss'],
})
export class PersonReceiptsComponent implements OnInit {
  receiptsList: PersonReceiptsListItem[] = [];

  dataTableSource: MatTableDataSource<any>;
  dataLoaded: boolean = false;

  viewReceiptBtn: CustomTableGenericButton = {
    icon: 'receipt',
    text: '',
    color: 'primary',
  };

  columns: string[] = [
    'id',
    'date',
    'status_semaphore',
    'payment_reason',
    'payment_type',
    'course_name',
    'actions',
  ];

  receiptsTable: CustomTableStructure;

  constructor(
    private APIPayments: ApiPaymentsService,
    private viewReceiptDialog: MatDialog,
    private alertDialog: MatDialog,
    private ui: UserInterfaceService,
    private identity: IdentityService,
    private utils: UtilsService,
  ) {}

  ngOnInit(): void {
    this.getReceipts();
  }

  getReceipts() {
    this.ui.spin$.next(true);
    this.APIPayments.getReceiptsByPerson(
      this.identity.getDecodedToken().id_person,
    ).subscribe(response => {
      const items = response.data;

      items.forEach((r: PersonReceiptsListItem) => {
        this.receiptsList.push({
          id: r.id,
          date: r.date,
          image: r.image,
          comments: r.comments,
          answer: r.answer,
          payment_reason: r.payment_reason,
          payment_type: r.payment_type,
          id_status: r.id_status,
          status_name: r.status_name,
          course_name: r.course_name,
          status_semaphore: this.utils.getReceiptStatusSemaphore(r.id_status),
        });
      });

      this.dataTableSource = new MatTableDataSource(this.receiptsList);

      // this.receiptsTable = { columns: this.columns, data: this.receiptsList };
      // this.changeRef.detectChanges();
      this.ui.spin$.next(false);
      this.dataLoaded = true;
    });
  }

  openReceiptDialog(receiptId: number, payment_type: string) {
    this.viewReceiptDialog.open(ImageDialogComponent, {
      data: {
        title: 'Comprobante #' + receiptId,
        url: this.receiptsList.find(r => r.id == receiptId)?.image,
        payment_type: payment_type,
      },
    });
  }

  delete(receiptId: number) {
    const dialogRef = this.alertDialog.open(AlertDialogComponent, {
      data: {
        title: 'Eliminar comprobante',
        content: '¿Confirma que desea eliminar este elemento?',
        status: 'warning',
        okButtonLabel: 'Eliminar',
        closeButtonLabel: 'Cancelar',
      },
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.APIPayments.deleteReceipt(receiptId).subscribe(() => {
          this.receiptsList = [];
          this.getReceipts();
        });
      }
    });
  }
}
