<div class="base-view">
  <section-header title="Mis Comprobantes"></section-header>

  <mat-card>
    <mat-card-content>
      <table mat-table [dataSource]="dataTableSource" style="width: 100%">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>N°</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="payment_type">
          <th mat-header-cell *matHeaderCellDef>Pago</th>
          <td mat-cell *matCellDef="let element">{{ element.payment_type }}</td>
        </ng-container>

        <ng-container matColumnDef="payment_reason">
          <th mat-header-cell *matHeaderCellDef>Concepto</th>
          <td mat-cell *matCellDef="let element">{{ element.payment_reason }}</td>
        </ng-container>

        <ng-container matColumnDef="course_name">
          <th mat-header-cell *matHeaderCellDef>Curso</th>
          <td mat-cell *matCellDef="let element">{{ element.course_name }}</td>
        </ng-container>

        <ng-container matColumnDef="status_semaphore">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let element">
            <p-chip
              class="text-nowrap"
              [label]="element.status_semaphore.label"
              [styleClass]="element.status_semaphore.class"
              [style.background-color]="element.status_semaphore.color"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; flex-direction: row">
              <button
                *ngIf="element.image.split('.').pop() !== 'pdf'"
                matTooltip="Ver Comprobante"
                mat-icon-button
                color="primary"
                (click)="openReceiptDialog(element.id, element.payment_type)"
                style="margin-right: 10px"
              >
                <mat-icon>receipt</mat-icon>
              </button>
              <a
                *ngIf="element.image.split('.').pop() === 'pdf'"
                matTooltip="Abrir PDF"
                [href]="element.image"
                target="_blank"
                color="primary"
                mat-icon-button
              >
                <mat-icon>open_in_new</mat-icon>
              </a>
              <button
                matTooltip="Eliminar"
                mat-icon-button
                *ngIf="element.id_status == null"
                color="warn"
                (click)="delete(element.id)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999" style="text-align: center">
            No se encontraron datos.
          </td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50, 100, 1000]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
