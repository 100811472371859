<div style="display: flex; margin-bottom: 20px">
  <div>
    <button
      type="button"
      mat-flat-button
      [color]="color"
      (click)="fileInput.click()"
      [disabled]="disableButton"
    >
      <mat-icon>{{ icon }}</mat-icon>
      {{ text }}
    </button>
  </div>
  <div style="margin: 9px">
    <input #fileInput type="file" hidden (change)="onChangeInputFile($event)" />
    <span>{{ fileName }}</span>
  </div>
</div>
