<div class="card">
  <ng-container *ngIf="isPortrait">
    <p-carousel
      [value]="licenses"
      [numVisible]="1"
      [numScroll]="1"
      orientation="vertical"
      verticalViewPortHeight="700px"
      [circular]="true"
    >
      <ng-template let-license pTemplate="license">
        <div class="license-item">
          <div class="license-item-content mb-3">
            <!-- <license-vertical *ngIf='personData && licenses' [license]="license" [person]='personData'
              [onlyView]='false'>
            </license-vertical> -->
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </ng-container>

  <ng-container *ngIf="!isPortrait">
    <p-carousel
      [value]="licenses"
      [numVisible]="1"
      [numScroll]="1"
      orientation="horizontal"
      [circular]="true"
    >
      <ng-template let-license pTemplate="license">
        <div class="license-item">
          <div class="license-item-content mb-3">
            <license-horizontal
              *ngIf="personData && licenses"
              [license]="license"
              [person]="personData"
              [onlyView]="false"
            >
            </license-horizontal>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </ng-container>
</div>
