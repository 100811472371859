<div style="flex: 1 1 1e-9px">
  <div
    *ngIf="
      !allPersonListTableStructure.data?.length &&
      !recordListTableStructure.data?.length
    "
  >
    <mat-spinner></mat-spinner>
  </div>
  <mat-card
    *ngIf="
      !(
        !allPersonListTableStructure.data?.length &&
        !recordListTableStructure.data?.length
      )
    "
  >
    <mat-card-content>
      <custom-table
        *ngIf="allPersonListTableStructure.data?.length && recordType === 'all'"
        [dataSource]="allPersonListTableStructure"
        [filterable]="true"
        [showActions]="true"
        [showEditButton]="false"
        [showDeleteButton]="false"
        [showDetailsButton]="false"
        [showGenericButton2]="true"
        [genericButton2]="createRecordButton"
        [genericButton2Title]="createRecordButton.title || 'Crear'"
        (genericButton2Pressed)="openCreateRecordDialog($event)"
      ></custom-table>
      <p
        *ngIf="
          !(
            !allPersonListTableStructure.data?.length &&
            !recordListTableStructure.data?.length
          ) &&
          !allPersonListTableStructure.data?.length &&
          recordType === 'all'
        "
      >
        No hay Legajos que mostrar
      </p>
      <custom-table
        *ngIf="recordListTableStructure.data?.length && recordType !== 'all'"
        [dataSource]="recordListTableStructure"
        [filterable]="true"
        [showActions]="true"
        [showEditButton]="true"
        [showDeleteButton]="false"
        [showDetailsButton]="false"
        [showGenericButton2]="false"
      >
      </custom-table>
      <p *ngIf="!recordListTableStructure.data?.length && recordType !== 'all'">
        No hay Records de {{ recordType }} que mostrar
      </p>
    </mat-card-content>
  </mat-card>
</div>
