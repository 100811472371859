<!-- <h2 *ngIf="!creatingNewRecord" mat-dialog-title>"{{ data.title }}"</h2> -->
<h2 mat-dialog-title>"{{ data.title }}"</h2>

<div style="position: relative"></div>
<!-- <div class="card-content" mat-dialog-content [formGroup]="recordForm"> -->

<div class="card-content" mat-dialog-content>
  <!-- <div class="user-record" *ngIf="personData && record !== undefined">
      RECORD ITEM
    </div> -->
  record
</div>
