import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterState } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserProfilesEnum } from 'src/app/enums/user-profiles-enum';
import { ApiCredentialsService } from 'src/app/services/api-credentials.service';
import { IdentityService } from 'src/app/services/identity.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay(),
    );

  isLoggedIn$: Observable<boolean>;
  useLegajo$: Observable<boolean>;
  legajoId$: Observable<number | string | undefined>;
  useCredential$: Observable<boolean>;
  useLicense$: Observable<boolean>;
  isScanner: boolean;
  isDarkMode: boolean = false;

  loggedUser$: Observable<any>;
  loggedUserName: string = 'Default User';

  userProfile: number | undefined;
  userProfiles: typeof UserProfilesEnum = UserProfilesEnum;
  AllUserProfiles: number[] = [];

  showNav = true;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private utils: UtilsService,
    private APICredentials: ApiCredentialsService,
    public identity: IdentityService,
  ) {
    const state: RouterState = router.routerState;
    this.loggedUser$ = this.identity.getLoggedUser;
    this.isScanner =
      state.snapshot.url === '/scanner' ||
      window.location.pathname === '/scanner';
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.identity.isLoggedIn;
    this.useLegajo$ = this.identity.getUseLegajo;
    this.legajoId$ = this.identity.getLegajoId;
    this.useCredential$ = this.identity.getUseCredential;
    this.useLicense$ = this.identity.getUseLicense;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showNav =
          !event.urlAfterRedirects.includes('user-credential') &&
          !event.urlAfterRedirects.includes('user-license');
      }
    });
    this.identity.getLoggedUser.subscribe((loggedUser: any) => {
      if (!loggedUser || loggedUser === 'invalid') return;
      this.loggedUserName = this.identity.getLoggedUserName();
      this.userProfile = loggedUser.id_profile;
      this.AllUserProfiles = [
        loggedUser.id_profile,
        ...loggedUser.extra_profiles,
      ] as number[];
    });

    const darkMode = localStorage.getItem('dark');
    if (darkMode) {
      this.isDarkMode = true;
      document.documentElement.classList.add('dark');
    }
  }

  showMenuByProfile(profile_ids: number[]): boolean {
    return profile_ids.some(profile => this.AllUserProfiles.includes(profile));
  }

  goHome() {
    this.router.navigate(['/']);
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }

  logout() {
    if (confirm('¿Desea cerrar sesión?')) {
      this.identity.logout();
    }
  }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('dark', 'true');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.removeItem('dark');
    }
  }
}
