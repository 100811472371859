<div class="base-view">
  <section-header
    title="Usuarios"
    [showNewButton]="
      allProfiles.includes(userProfilesValues.ADMIN) ||
      allProfiles.includes(userProfilesValues.SCHOOL) ||
      allProfiles.includes(userProfilesValues.SECRETARY) ||
      allProfiles.includes(userProfilesValues.CREDENTIAL)
    "
    (newButtonPressed)="new()"
  />
  <mat-card>
    <mat-tab-group animationDuration="250ms">
      <mat-tab label="Todos los usuarios">
        <div class="p-4">
          <custom-table
            *ngIf="dataLoadedALL"
            [dataSource]="tableAllStudents"
            [paginatorPageSize]="20"
            [filterable]="true"
            [showActions]="true"
            [showEditButton]="
              allProfiles.includes(userProfilesValues.ADMIN) ||
              allProfiles.includes(userProfilesValues.SCHOOL) ||
              allProfiles.includes(userProfilesValues.SECRETARY) ||
              allProfiles.includes(userProfilesValues.CREDENTIAL)
            "
            [showDeleteButton]="
              allProfiles.includes(userProfilesValues.ADMIN) ||
              allProfiles.includes(userProfilesValues.SCHOOL)
            "
            (editButtonPressed)="goToEdit($event)"
            (deleteButtonPressed)="deleteStudent($event)"
            [showGenericButton1]="
              allProfiles.includes(userProfilesValues.ADMIN) ||
              allProfiles.includes(userProfilesValues.SCHOOL)
            "
            [genericButton1]="enrollBtnIcon"
            genericButton1Title="Asignar inscripción"
            (genericButton1Pressed)="showEnrollDialog($event)"
            [showGenericButton2]="
              allProfiles.includes(userProfilesValues.ADMIN) ||
              allProfiles.includes(userProfilesValues.TREASURY)
            "
            [genericButton2]="addPaymentBtn"
            genericButton2Title="Agregar Comprobante"
            (genericButton2Pressed)="showCourseListDialog($event)"
            [showGenericButton3]="
              allProfiles.includes(userProfilesValues.ADMIN) ||
              allProfiles.includes(userProfilesValues.SECRETARY) ||
              allProfiles.includes(userProfilesValues.CREDENTIAL)
            "
            [genericButton3]="credentialBtn"
            genericButton3Title="Credencial"
            (genericButton3Pressed)="showCredentialDialog($event)"
          ></custom-table>
        </div>
      </mat-tab>
      <mat-tab
        label="Inscripciones pendientes"
        *ngIf="
          allProfiles.includes(userProfilesValues.ADMIN) ||
          allProfiles.includes(userProfilesValues.SCHOOL)
        "
      >
        <div class="p-4">
          <students-by-status-table
            [enrollmentStatus]="enrollmentStatus.PENDING"
          ></students-by-status-table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
