import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTableColumnType } from 'src/app/components/custom-table/enums/custom-table-column-type.enum';
import { CustomTableColumn } from 'src/app/components/custom-table/interfaces/custom-table-column';
import { CustomTableStructure } from 'src/app/components/custom-table/interfaces/custom-table-structure';
import { CourseListItem } from 'src/app/models/course-list-item';
import { ApiCoursesService } from 'src/app/services/api-courses.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss'],
})
export class CoursesListComponent implements OnInit {
  courses: CourseListItem[] = [];
  dataLoaded: boolean = false;

  coursesColumns: CustomTableColumn[] = [
    { name: 'id', text: 'N° Curso', type: CustomTableColumnType.TEXT },
    { name: 'name', text: 'Nombre', type: CustomTableColumnType.TEXT },
    {
      name: 'vacancies_enrollments',
      text: 'Cubiertas / Vacantes',
      type: CustomTableColumnType.TEXT,
    },
    { name: 'dates', text: 'Fechas', type: CustomTableColumnType.TEXT },
    {
      name: 'enrollment_price',
      text: '$ Matrícula',
      type: CustomTableColumnType.NUMBER,
    },
    { name: 'fees', text: 'Cuotas', type: CustomTableColumnType.TEXT },
    { name: 'fee_price', text: '$ Cuota', type: CustomTableColumnType.NUMBER },
    {
      name: 'enrollment_enabled',
      text: 'Abierto',
      type: CustomTableColumnType.BOOLEAN_SEMAPHORE,
    },
  ];

  allCoursesTable: CustomTableStructure = {
    columns: this.coursesColumns,
    data: this.courses,
  };
  constructor(
    private utils: UtilsService,
    private APICourses: ApiCoursesService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.APICourses.getList().subscribe(response => {
      this.allCoursesTable.data = response.data.map(
        (course: CourseListItem) => {
          return {
            ...course,
            enrollment_enabled: this.utils.getValidEnabledBooleanSemaphore(
              course.enrollment_enabled,
            ),
            fees: this.utils.ifSingleFee(
              course.fees,
              course.fee_price,
              course.enrollment_price,
            )?.fees,
            fee_price: this.utils.ifSingleFee(
              course.fees,
              course.fee_price,
              course.enrollment_price,
            )?.fee_price,
          };
        },
      );
      this.allCoursesTable = {
        columns: this.coursesColumns,
        data: this.allCoursesTable.data,
      };
      this.dataLoaded = true;
    });
  }

  new() {
    this.router.navigate(['courses-form']);
  }

  goToEdit(id: number) {
    this.router.navigate(['courses-form', id]);
  }

  delete(id: number) {
    this.APICourses.delete(id).subscribe(response => {
      this.getList();
    });
  }
}
