export class License {
  enabled: string | boolean;
  id: number | string;
  id_person: number | string;
  firstname?: string;
  lastname?: string;
  gender?: string;
  identity_number?: string;
  photo?: string;
  status?: string;
  type: string;
  typeName?: string;
  valid_until: string;
  updated_at?: string;
  valid?: boolean;
}

export interface LicenseTypeItem {
  code: string;
  name: string;
}

export interface LicenseListTable {
  [key: string]: License[];
}
