import { ReceiptDetailsFees } from './receipt-details-fees';

export class ReceiptDetails {
  id_receipt: number;
  id_payment: number;
  id_person: number;
  id_course: number;
  person_name: string;
  course_name: string;
  date: Date;
  transfer_date: Date;
  transfer_owner: string;
  comments: string;
  image: string;
  fees: ReceiptDetailsFees[];
  enrollment_price: number;
  fee_price: number;

  constructor() {
    this.fees = new Array<ReceiptDetailsFees>();
  }
}
