import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AES, enc, pad } from 'crypto-js';
import { LoginRequest } from 'src/app/models/login-request';
import { IdentityService } from 'src/app/services/identity.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  encrypt: Record<string, any> = {};

  constructor(
    private fb: UntypedFormBuilder,
    private identity: IdentityService,
    private router: Router,
    private ui: UserInterfaceService,
  ) {
    this.loginForm = this.fb.group({
      username: [
        null,
        [
          Validators.required,
          Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
        ],
      ],
      password: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
    this.encrypt['key'] = enc.Hex.parse('ecb4bf74b9d399ba25f737c7529f2437');
    this.encrypt['iv'] = enc.Hex.parse('eb2daf7ba35c59225faafc3752227d94');
  }

  login() {
    if (this.loginForm.valid) {
      this.ui.spin$.next(true);

      this.encrypt['result'] = AES.encrypt(
        this.loginForm.get('password')!.value,
        this.encrypt['key'],
        {
          iv: this.encrypt['iv'],
          padding: pad.ZeroPadding,
        },
      ).toString();

      const loginRequest: LoginRequest = {
        username: this.loginForm.get('username')!.value,
        password: this.encrypt['result'],
      };

      this.identity.login(loginRequest);

      this.ui.spin$.next(false);
      this.loginForm.get('username')?.setValue('');
      this.loginForm.get('password')?.setValue('');
    }
  }

  goToRegistration() {
    this.router.navigate(['registration']);
  }

  goToForgotPassword() {
    this.router.navigate(['forgot-password']);
  }
}
