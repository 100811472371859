import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Course } from 'src/app/models/course';
import { ApiCoursesService } from 'src/app/services/api-courses.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-courses-list-dialog',
  templateUrl: './courses-list-dialog.component.html',
  styleUrls: ['./courses-list-dialog.component.scss'],
})
export class CoursesListDialogComponent implements OnInit {
  courses: Course[] = [];

  courseId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; personId: number },
    private APICourses: ApiCoursesService,
    private ui: UserInterfaceService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getCourses();
  }

  getCourses() {
    this.ui.spin$.next(true);
    this.APICourses.getAll().subscribe(response => {
      this.courses = response.data;
      this.ui.spin$.next(false);
    });
  }

  onCourseChange(courseId: number) {
    this.courseId = courseId;
  }

  goToAddPayment() {
    this.router.navigate([
      'add-payment/' + this.courseId + '/' + this.data.personId,
    ]);
  }
}
