<form [formGroup]="regForm" novalidate (ngSubmit)="register()">
  <div style="display: flex; flex-direction: row">
    <mat-form-field
      style="
        margin-right: 10px;
        flex: 1 1 0%;

        max-width: 50%;
      "
      appearance="outline"
    >
      <mat-label>Nacionalidad</mat-label>
      <mat-select formControlName="id_nationality">
        <mat-option *ngFor="let n of nationalities" [value]="n.id">{{
          n.name
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="regForm.get('id_nationality')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      style="
        margin-right: 10px;
        flex: 1 1 0%;

        max-width: 50%;
      "
      appearance="outline"
    >
      <mat-label>Fecha de Nac.</mat-label>
      <input matInput type="date" formControlName="birthdate" />
      <mat-error *ngIf="regForm.get('birthdate')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div style="display: flex; flex-direction: row">
    <mat-form-field
      style="
        margin-right: 10px;
        flex: 1 1 100%;

        max-width: 80%;
      "
      appearance="outline"
    >
      <mat-label>Domicilio</mat-label>
      <input matInput type="text" formControlName="address" />
      <mat-error *ngIf="regForm.get('address')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field style="flex: 1 1 100%; max-width: 20%" appearance="outline">
      <mat-label>CP</mat-label>
      <input matInput type="text" formControlName="postal_code" />
      <mat-error *ngIf="regForm.get('postal_code')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div style="display: flex; flex-direction: row">
    <mat-form-field
      style="
        margin-right: 10px;
        flex: 1 1 100%;

        max-width: 50%;
      "
      appearance="outline"
    >
      <mat-label>Provincia</mat-label>
      <mat-select
        formControlName="id_state"
        (valueChange)="onStateChange($event)"
      >
        <mat-option *ngFor="let s of states" [value]="s.id">{{
          s.name
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="regForm.get('id_state')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      style="
        margin-right: 10px;
        flex: 1 1 100%;

        max-width: 50%;
      "
      appearance="outline"
    >
      <mat-label>Localidad</mat-label>
      <mat-select formControlName="id_city">
        <mat-option *ngFor="let c of citiesByState" [value]="c.id">{{
          c.name
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="regForm.get('id_city')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div style="display: flex; flex-direction: row">
    <mat-form-field
      style="
        margin-right: 10px;
        flex: 1 1 100%;

        max-width: 50%;
      "
      appearance="outline"
    >
      <mat-label>Género</mat-label>
      <mat-select formControlName="gender">
        <mat-option value="M">Masculino</mat-option>
        <mat-option value="F">Femenino</mat-option>
      </mat-select>
      <mat-error *ngIf="regForm.get('gender')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      style="
        margin-right: 10px;
        flex: 1 1 100%;

        max-width: 50%;
      "
      appearance="outline"
    >
      <mat-label>Teléfono</mat-label>
      <input matInput type="tel" formControlName="phone" />
      <mat-error *ngIf="regForm.get('phone')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
      <mat-error *ngIf="regForm.get('phone')?.hasError('pattern')">
        Ingrese un telefono válido.
      </mat-error>
    </mat-form-field>
  </div>

  <div style="display: flex; flex-direction: column">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-error *ngIf="regForm.get('email')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
      <mat-error *ngIf="regForm.get('email')?.hasError('pattern')">
        Ingrese una casilla de correo válida.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="showPassword">
      <mat-label>Cambiar contraseña</mat-label>
      <input
        matInput
        [type]="passwordVisible ? 'password' : 'text'"
        formControlName="password"
      />
      <button
        *ngIf="passwordVisible"
        type="button"
        matSuffix
        mat-icon-button
        (click)="passwordVisible = !passwordVisible"
      >
        <mat-icon>visibility</mat-icon>
      </button>
      <button
        *ngIf="!passwordVisible"
        type="button"
        matSuffix
        mat-icon-button
        (click)="passwordVisible = !passwordVisible"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>
      <mat-error *ngIf="regForm.get('password')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
      <mat-error *ngIf="regForm.get('password')?.hasError('minlength')">
        La contraseña debe tener al menos 8 caracteres.
      </mat-error>
      <mat-error *ngIf="regForm.get('password')?.hasError('pattern')">
        Debe tener al menos una letra minúscula, una letra mayúscula y un
        número.
      </mat-error>
    </mat-form-field>
  </div>

  <div
    style="
      margin: 20px 25px 30px;
      display: flex;
      flex-direction: row;

      place-content: center space-between;
      align-items: center;
    "
  >
    <button
      mat-flat-button
      style="flex: 1 1 100%; max-width: 25%; min-width: min-content"
      color="primary"
      type="submit"
      [disabled]="showLoading"
    >
      Guardar
    </button>
  </div>
  <span *ngIf="showLoading"><mat-spinner></mat-spinner> Cargando...</span>
</form>

<hr
  style="background: #cfcfcf; height: 1px; border: 0px; margin: 60px 0 40px"
/>

<form
  [formGroup]="regForm"
  novalidate
  (ngSubmit)="updateSensitiveData()"
  style="margin: 25px"
>
  <mat-card class="sensitive-data-alert" [class]="profileStatusMessage.status">
    <mat-icon class="sensitive-data-alert-icon">{{
      profileStatusMessage.icon
    }}</mat-icon>
    <span>{{ profileStatusMessage.content }}</span>
  </mat-card>
  <div
    style="
      display: flex;
      flex-direction: row;

      flex-wrap: wrap;
    "
  >
    <mat-form-field
      style="margin-right: 10px; min-width: 37%"
      appearance="outline"
    >
      <mat-label>Nombre</mat-label>
      <input matInput type="text" formControlName="firstname" />
      <mat-error *ngIf="regForm.get('firstname')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      style="margin-right: 10px; min-width: 37%"
      appearance="outline"
    >
      <mat-label>Apellido</mat-label>
      <input matInput type="text" formControlName="lastname" />
      <mat-error *ngIf="regForm.get('lastname')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      style="margin-right: 10px; min-width: 22%"
      appearance="outline"
    >
      <mat-label>DNI</mat-label>
      <input matInput type="text" formControlName="identity_number" />
      <mat-error *ngIf="regForm.get('identity_number')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
      <mat-error *ngIf="regForm.get('identity_number')?.hasError('pattern')">
        Este campo sólo permite <strong>números.</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <mat-card class="user-images-container" *ngIf="userId != 0 || personId != 0">
    <mat-card-content>
      <div style="display: flex; flex-direction: row">
        <span
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1 1 0%;
            margin-right: 10px;
          "
        >
          <h4 class="user-image-title">Foto</h4>
          <img class="user-images" [src]="userPhoto" />
          <file-button
            style="margin: 10px 0px -10px; width: fit-content; flex: 1 1 1e-9px"
            [disableButton]="allowEditData"
            id="fileButton_photo"
            color="primary"
            text="Foto"
            icon="add"
            (fileChanged)="onFileChanged('photo', $event)"
          >
          </file-button>
          <span
            class="form-custom-error"
            *ngIf="
              regForm.get('photo')?.hasError('required') &&
              (regForm.get('photo')?.dirty || regForm.get('photo')?.touched)
            "
          >
            No se agregó ningún archivo.
          </span>
          <span class="form-custom-error" *ngIf="!receiptFileValid">
            El archivo agregado no tiene el formato correcto. Formatos
            permitidos: jpg, jpeg, png, pdf.
          </span>
        </span>
        <span
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1 1 0%;
            margin-right: 10px;
          "
        >
          <h4 class="user-image-title">DNI Frente</h4>
          <img class="user-images" [src]="userIdCardFront" />
          <file-button
            style="margin: 10px 0px -10px; width: fit-content; flex: 1 1 1e-9px"
            [disableButton]="allowEditData"
            id="fileButton_identity_card_front"
            color="primary"
            text="DNI Frente"
            icon="add"
            (fileChanged)="onFileChanged('identity_card_front', $event)"
          >
          </file-button>
          <span
            class="form-custom-error"
            *ngIf="
              regForm.get('identity_card_front')?.hasError('required') &&
              (regForm.get('identity_card_front')?.dirty ||
                regForm.get('identity_card_front')?.touched)
            "
          >
            No se agregó ningún archivo.
          </span>
          <span class="form-custom-error" *ngIf="!receiptFileValid">
            El archivo agregado no tiene el formato correcto. Formatos
            permitidos: jpg, jpeg, png, pdf.
          </span>
        </span>
        <span
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1 1 0%;
            margin-right: 10px;
          "
        >
          <h4 class="user-image-title">DNI Dorso</h4>
          <img class="user-images" [src]="userIdCardBack" />
          <file-button
            style="margin: 10px 0px -10px; width: fit-content; flex: 1 1 1e-9px"
            [disableButton]="allowEditData"
            id="fileButton_identity_card_back"
            color="primary"
            text="DNI Dorso"
            icon="add"
            (fileChanged)="onFileChanged('identity_card_back', $event)"
          >
          </file-button>
          <span
            class="form-custom-error"
            *ngIf="
              regForm.get('identity_card_back')?.hasError('required') &&
              (regForm.get('identity_card_back')?.dirty ||
                regForm.get('identity_card_back')?.touched)
            "
          >
            No se agregó ningún archivo.
          </span>
          <span class="form-custom-error" *ngIf="!receiptFileValid">
            El archivo agregado no tiene el formato correcto. Formatos
            permitidos: jpg, jpeg, png, pdf.
          </span>
        </span>
      </div>
    </mat-card-content>
  </mat-card>
  <div
    style="
      margin: 20px 10px 30px 20px;
      display: flex;
      flex-direction: row;

      place-content: center space-between;
      align-items: center;
    "
  >
    <button
      mat-flat-button
      style="flex: 1 1 100%; max-width: 25%; min-width: min-content"
      color="primary"
      type="submit"
      [disabled]="allowEditData"
    >
      Guardar
    </button>
    <button
      mat-flat-button
      style="flex: 1 1 100%; max-width: 25%; min-width: min-content"
      color="primary"
      [disabled]="showLoading || !allowEditData || !allowEditButton"
      (click)="toggleEditData()"
    >
      Editar
    </button>
  </div>
</form>
