import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTableColumnType } from 'src/app/components/custom-table/enums/custom-table-column-type.enum';
import { CustomTableColumn } from 'src/app/components/custom-table/interfaces/custom-table-column';
import { CustomTableGenericButton } from 'src/app/components/custom-table/interfaces/custom-table-generic-button';
import { CustomTableStructure } from 'src/app/components/custom-table/interfaces/custom-table-structure';
import { ChangeProfileRequestsItem } from 'src/app/models/change-profile-requests-item';
import { ApiChangeProfileRequestsService } from 'src/app/services/api-change-profile-requests.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-change-profile-requests',
  templateUrl: './change-profile-requests.component.html',
  styleUrls: ['./change-profile-requests.component.scss'],
})
export class ChangeProfileRequestsComponent implements OnInit {
  constructor(
    private router: Router,
    private ApiChangeProfileRequests: ApiChangeProfileRequestsService,
    private changeRef: ChangeDetectorRef,
    private ui: UserInterfaceService,
  ) {}

  requestsList: ChangeProfileRequestsItem[] = [];
  dataLoadedALL: boolean = false;
  detailsBtn: CustomTableGenericButton = {
    icon: 'info',
    text: 'Detalles',
    color: 'primary',
  };

  tableAllStudents: CustomTableStructure;
  columnsPE: CustomTableColumn[] = [
    { name: 'id', text: 'N° Solicitud', type: CustomTableColumnType.TEXT },
    { name: 'person_id', text: 'Legajo', type: CustomTableColumnType.TEXT },
    { name: 'person_name', text: 'Nombre', type: CustomTableColumnType.TEXT },
    { name: 'identity_number', text: 'DNI', type: CustomTableColumnType.TEXT },
    { name: 'email', text: 'E-mail', type: CustomTableColumnType.TEXT },
  ];

  ngOnInit(): void {
    this.ui.spin$.next(true);
    this.getProfileRequests();
  }

  getProfileRequests() {
    this.ApiChangeProfileRequests.getAllChangeRequests().subscribe(response => {
      this.requestsList = response.data
        .map((p: ChangeProfileRequestsItem) => {
          if (p.person_name.split(', ')[0] === '') {
            p.person_name = `(No especificado)`;
          }
          return p;
        })
        .sort((a: ChangeProfileRequestsItem, b: ChangeProfileRequestsItem) => {
          if (this.isSpecial(a) && !this.isSpecial(b)) {
            return 1;
          } else if (!this.isSpecial(a) && this.isSpecial(b)) {
            return -1;
          } else {
            return a.person_name.localeCompare(b.person_name);
          }
        });

      this.tableAllStudents = {
        columns: this.columnsPE,
        data: this.requestsList,
      };
      this.changeRef.detectChanges();
      this.dataLoadedALL = true;
      this.ui.spin$.next(false);
    });
  }

  isSpecial(person: ChangeProfileRequestsItem): boolean {
    return person.person_name === `(No especificado)`;
  }

  goToDetails(item: number) {
    this.router.navigate(['/change-profile-requests/' + item]);
  }
}
