import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';
import { ElementsAlertDialog } from 'src/app/models/elements-alert-dialog';

@Component({
  selector: 'signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss'],
  host: {
    '(keyup.ctrl.k)': 'clear()',
  },
})
export class SignatureDialogComponent implements OnInit {
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  signaturePad: SignaturePad;
  showClearButton: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SignatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public elements: ElementsAlertDialog,
  ) {}

  ngOnInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement);
    this.signaturePad.addEventListener('beginStroke', () => {
      !this.showClearButton && (this.showClearButton = true);
    });
  }

  onClickOk(): void {
    if (this.signaturePad.isEmpty()) {
      alert('Debe firmar para continuar'); // Not necessary but porlasdudas
      return;
    }
    this.dialogRef.close(this.signaturePad.toData());
  }

  onClickCancel(): void {
    this.showClearButton = false;
    this.dialogRef.close();
  }

  onClickReset(): void {
    this.showClearButton = false;
    this.signaturePad.clear();
  }
}
