import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AES, enc, pad } from 'crypto-js';
import { UserProfilesEnum } from 'src/app/enums/user-profiles-enum';
import { City } from 'src/app/models/city';
import { Nationality } from 'src/app/models/nationality';
import { RegistrationRequest } from 'src/app/models/registration-request';
import { State } from 'src/app/models/state';
import { ApiCitiesService } from 'src/app/services/api-cities.service';
import { ApiNationalitiesService } from 'src/app/services/api-nationalities.service';
import { ApiPersonsService } from 'src/app/services/api-persons.service';
import { ApiStatesService } from 'src/app/services/api-states.service';
import { ApiUsersService } from 'src/app/services/api-users.service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit {
  @Input() userId: number = 0;
  @Input() personId: number = 0;
  @Input() userIdDelPerson: number = 0;
  @Input() userMailDelPerson: string = '';

  regForm: UntypedFormGroup;
  encrypt: Record<string, any> = {};

  initialData: any;
  showLoading: boolean = false;
  receiptFileValid: boolean = true;
  active: boolean = false;
  passwordValidator: Array<any> = [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/),
  ];

  states: State[] = [];
  cities: City[] = [];
  citiesByState: City[] = [];
  nationalities: Nationality[] = [];

  showPassword: boolean = true;
  passwordVisible: boolean = true;

  userPhoto: string;
  userIdCardFront: string;
  userIdCardBack: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private identity: IdentityService,
    private APIStates: ApiStatesService,
    private APICities: ApiCitiesService,
    private APINationalities: ApiNationalitiesService,
    private APIUsers: ApiUsersService,
    private APIPersons: ApiPersonsService,
    private ui: UserInterfaceService,
    private utils: UtilsService,
    private route: ActivatedRoute,
  ) {
    this.regForm = this.fb.group({
      id_user: [0, Validators.required],
      id_person: [0, Validators.required],
      id_profile: [UserProfilesEnum.USER, Validators.required],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      id_nationality: [null, Validators.required],
      identity_number: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]\d*$/),
        ]),
      ],
      birthdate: [null, Validators.required],
      active: null,
      address: [null, Validators.required],
      postal_code: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]\d*$/)],
      ],
      id_state: [null, Validators.required],
      id_city: [null, Validators.required],
      gender: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
        ],
      ],
      password: [null, this.passwordValidator],
      photo: [null, Validators.required],
      identity_card_front: [null, Validators.required],
      identity_card_back: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.encrypt['key'] = enc.Hex.parse('ecb4bf74b9d399ba25f737c7529f2437');
    this.encrypt['iv'] = enc.Hex.parse('eb2daf7ba35c59225faafc3752227d94');

    this.ui.spin$.next(true);
    this.APIStates.getAll().subscribe(response => {
      this.states = response.data;

      this.APICities.getAll().subscribe(response => {
        this.cities = response.data;
      });

      this.APINationalities.getAll().subscribe(response => {
        this.nationalities = response.data;

        if (this.userId != 0 || this.personId != 0) {
          this.APIUsers.getUserPerson(this.userId, this.personId).subscribe(
            response => {
              console.log('getUserPerson', response.data);
              this.userIdDelPerson = response.data['id_user'];
              this.userMailDelPerson = response.data['email'];
              this.regForm
                .get('password')
                ?.setValidators(this.passwordValidator.slice(1));
              this.regForm.get('password')?.updateValueAndValidity();
              response.data.active = response.data.active === '1';
              this.regForm.patchValue(response.data);
              this.initialData = response.data;

              // ASIGNO LAS IMAGENES A LAS VARIABLES Y LIMPIO LOS PARAMETROS DEL FORM
              this.userPhoto = this.regForm.get('photo')?.value;
              this.userIdCardFront = this.regForm.get(
                'identity_card_front',
              )?.value;
              this.userIdCardBack =
                this.regForm.get('identity_card_back')?.value;

              this.regForm.get('photo')?.setValue(null);
              this.regForm.get('identity_card_front')?.setValue(null);
              this.regForm.get('identity_card_back')?.setValue(null);

              this.onStateChange(this.regForm.get('id_state')!.value);
              this.ui.spin$.next(false);
            },
          );
        } else {
          this.ui.spin$.next(false);
        }
      });
    });

    // SI EL USUARIO ESTA LOGUEADO ES PORQUE UN USUARIO ESCUELA ESTA DANDO DE ALTA EL ALUMNO
    if (this.identity.tokenExists()) {
      // this.showPassword = false;
      this.passwordVisible = true;
      this.regForm.get('photo')?.clearValidators();
      this.regForm.get('identity_card_front')?.clearValidators();
      this.regForm.get('identity_card_back')?.clearValidators();
    }
  }

  onFileChanged(field: string, event: any) {
    this.receiptFileValid = this.utils.receiptFileValidation(event.type);
    this.regForm.get(field)?.setValue(event);
  }

  onStateChange(event: any) {
    this.citiesByState = this.cities.filter(
      c => c.id_state.toString() == event,
    );
  }

  impersonatePerson() {
    const impersonateRequest = {
      impersonate: this.initialData?.email,
      required_by: 33,
    };

    this.identity.impersonate(impersonateRequest);
  }

  resendActivationEmail() {
    this.APIUsers.emailActivation(this.personId).subscribe(response => {
      alert('Enviamos un email a su casilla de correo electrónico.');
    });
  }

  resendPasswordRecoveryEmail() {
    this.APIUsers.PasswordRecoveryEmail(this.personId).subscribe(response => {
      alert(
        `Enviamos un email a la casilla de correo electrónico: ${this.initialData?.email}`,
      );
    });
  }

  register(): void {
    if (this.regForm.valid && this.receiptFileValid) {
      this.ui.spin$.next(true);
      const regRequest: RegistrationRequest = this.regForm.getRawValue();

      regRequest['password'] = AES.encrypt(
        regRequest['password'],
        this.encrypt['key'],
        {
          iv: this.encrypt['iv'],
          padding: pad.ZeroPadding,
        },
      ).toString();

      this.APIUsers.register(regRequest).subscribe(
        response => {
          if (this.userId != 0 || this.personId != 0) {
            alert('Usuario modificado correctamente');
            this.APIUsers.getUserPerson(this.userId, this.personId).subscribe(
              response => {
                this.regForm
                  .get('password')
                  ?.setValidators(this.passwordValidator.slice(1));
                this.regForm.get('password')?.updateValueAndValidity();
                response.data.active = response.data.active === '1';
                this.regForm.patchValue(response.data);
                this.initialData = response.data;

                // ASIGNO LAS IMAGENES A LAS VARIABLES Y LIMPIO LOS PARAMETROS DEL FORM
                this.userPhoto = this.regForm.get('photo')?.value;
                this.userIdCardFront = this.regForm.get(
                  'identity_card_front',
                )?.value;
                this.userIdCardBack =
                  this.regForm.get('identity_card_back')?.value;

                this.regForm.get('photo')?.setValue(null);
                this.regForm.get('identity_card_front')?.setValue(null);
                this.regForm.get('identity_card_back')?.setValue(null);

                this.onStateChange(this.regForm.get('id_state')!.value);
                this.ui.spin$.next(false);
              },
            );
          } else {
            this.ui.spin$.next(false);
            alert(
              'Enviamos un email a tu casilla de correo electrónico con las instrucciones para activar tu usuario. Recordá revisar tu carpeta de SPAM en el caso que no lo veas en tu bandeja de entrada.',
            );
            this.router.navigate(['students-list']);
          }
        },
        error => {
          alert(
            error.error.msg ?? 'Ocurrio un error al realizar la operación.',
          );
          this.ui.spin$.next(false);
        },
      );
    } else {
      Object.keys(this.regForm.controls).forEach(field => {
        const control = this.regForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
