<mat-form-field appearance="outline" *ngIf="filterable">
  <mat-label>Filtrar</mat-label>
  <input
    matInput
    (keyup)="filter($event)"
    placeholder="Ingrese el término a buscar..."
    #input
  />
</mat-form-field>

<table mat-table [dataSource]="data">
  <ng-container
    *ngFor="let column of getColumnsByType(columnTypes.TEXT)"
    matColumnDef="{{ column.name }}"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.text }}</th>
    <td style="padding: 10px" mat-cell *matCellDef="let element">
      {{ element[column.name] }}
    </td>
  </ng-container>

  <ng-container
    *ngFor="let column of getColumnsByType(columnTypes.NUMBER)"
    matColumnDef="{{ column.name }}"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.text }}</th>
    <td style="padding: 10px" mat-cell *matCellDef="let element">
      {{ element[column.name] | number: '1.0-0' }}
    </td>
  </ng-container>

  <ng-container
    *ngFor="let column of getColumnsByType(columnTypes.DATE)"
    matColumnDef="{{ column.name }}"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.text }}</th>
    <td style="padding: 10px" mat-cell *matCellDef="let element">
      {{ element[column.name] | date: 'dd/MM/yyyy' }}
    </td>
  </ng-container>

  <ng-container
    *ngFor="let column of getColumnsByType(columnTypes.BOOLEAN)"
    matColumnDef="{{ column.name }}"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.text }}</th>
    <td style="padding: 10px" mat-cell *matCellDef="let element">
      <!-- a checkobx that indicate de column state by 1 or 0  -->
      <mat-checkbox [checked]="element[column.name] == '1'" disabled
        >{{ element[column.name] == '1' ? 'Si' : 'No' }}
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container
    *ngFor="let column of getColumnsByType(columnTypes.SEMAPHORE)"
    matColumnDef="{{ column.name }}"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.text }}</th>
    <td style="padding: 10px" mat-cell *matCellDef="let element">
      <p-chip
        class="text-nowrap"
        [label]="element[column.name].label"
        [icon]="element[column.name].icon"
        [styleClass]="element[column.name].class"
        [style.background-color]="element[column.name].color"
      />
    </td>
  </ng-container>

  <ng-container
    *ngFor="let column of getColumnsByType(columnTypes.BOOLEAN_SEMAPHORE)"
    matColumnDef="{{ column.name }}"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.text }}</th>
    <td style="padding: 10px" mat-cell *matCellDef="let element">
      <p-chip
        class="text-nowrap"
        [icon]="element[column.name].icon"
        [styleClass]="element[column.name].class"
        [style.background-color]="element[column.name].background"
        [style.color]="element[column.name].color"
      />
    </td>
  </ng-container>

  <ng-container *ngIf="showActions" matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td style="padding: 10px" mat-cell *matCellDef="let element">
      <div style="display: flex; flex-direction: row">
        <button
          matTooltip="Editar"
          mat-icon-button
          *ngIf="showEditButton"
          (click)="onClickEditButton(element.id)"
          class="basic-action edit mr-2"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          matTooltip="Eliminar"
          mat-icon-button
          *ngIf="showDeleteButton"
          (click)="onClickDeleteButton(element.id)"
          class="basic-action delete mr-2"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          matTooltip="Detalles"
          mat-button
          *ngIf="showDetailsButton"
          (click)="onClickDetailsButton(element.id)"
          class="basic-action details mr-2"
        >
          <mat-icon>info</mat-icon>
          <span class="text-sm"> Detalles </span>
        </button>

        <button
          [matTooltip]="genericButton1Title || genericButton1.text"
          mat-flat-button
          *ngIf="showGenericButton1"
          color="{{ genericButton1.color }}"
          (click)="onClickGenericButton1(element.id)"
          style="margin-right: 10px"
        >
          <mat-icon
            *ngIf="genericButton1.icon"
            class="!overflow-visible"
            [class]="{ '!-mr-2': !genericButton1.text }"
            >{{ genericButton1.icon }}</mat-icon
          >
          <span>
            {{ genericButton1.text }}
          </span>
        </button>

        <button
          [matTooltip]="genericButton2Title || genericButton2.text"
          mat-flat-button
          *ngIf="showGenericButton2"
          color="{{ genericButton2.color }}"
          (click)="onClickGenericButton2(element)"
          style="margin-right: 10px"
        >
          <mat-icon
            *ngIf="genericButton2.icon"
            class="!overflow-visible"
            [class]="{ '!-mr-2': !genericButton2.text }"
            >{{ genericButton2.icon }}</mat-icon
          >
          <span>
            {{ genericButton2.text }}
          </span>
        </button>

        <button
          [matTooltip]="genericButton3Title || genericButton3.text"
          mat-flat-button
          *ngIf="showGenericButton3"
          color="{{ genericButton3.color }}"
          (click)="onClickGenericButton3(element)"
          style="margin-right: 10px"
        >
          <mat-icon
            *ngIf="genericButton3.icon"
            class="!overflow-visible"
            [class]="{ '!-mr-2': !genericButton3.text }"
            >{{ genericButton3.icon }}</mat-icon
          >
          <span>
            {{ genericButton3.text }}
          </span>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9999" style="text-align: center">
      No se encontraron datos.
    </td>
  </tr>
</table>

<mat-paginator
  [pageSize]="paginatorPageSize"
  [length]="rowsCount"
  (page)="pageChanged($event)"
  [pageSizeOptions]="[5, 10, 20, 50, 100, 1000]"
  showFirstLastButtons
  aria-label="Select page of periodic elements"
>
</mat-paginator>
