import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { CustomTableColumnType } from 'src/app/components/custom-table/enums/custom-table-column-type.enum';
import { CustomTableColumn } from 'src/app/components/custom-table/interfaces/custom-table-column';
import { CustomTableStructure } from 'src/app/components/custom-table/interfaces/custom-table-structure';
import { ReceiptsByStatusTableComponent } from 'src/app/components/receipts-by-status-table/receipts-by-status-table.component';
import { ReceiptStatusEnum } from 'src/app/enums/receipt-status-enum';
import { ReceiptListItem } from 'src/app/models/receipt-list-item';
import { ApiPaymentsService } from 'src/app/services/api-payments.service';

@Component({
  selector: 'app-payment-receipts-list',
  templateUrl: './payment-receipts-list.component.html',
  styleUrls: ['./payment-receipts-list.component.scss'],
})
export class PaymentReceiptsListComponent implements OnInit {
  @ViewChild('pendingReceipts') pendingReceipts: ReceiptsByStatusTableComponent;
  @ViewChild('approvedReceipts')
  approvedReceipts: ReceiptsByStatusTableComponent;
  @ViewChild('rejectedReceipts')
  rejectedReceipts: ReceiptsByStatusTableComponent;

  tabSelected: number = 0;

  receiptList: ReceiptListItem[] = [];

  columns: CustomTableColumn[] = [
    { name: 'id', text: '#', type: CustomTableColumnType.TEXT },
    { name: 'receipt_date', text: 'Fecha', type: CustomTableColumnType.DATE },
    { name: 'course_name', text: 'Curso', type: CustomTableColumnType.TEXT },
    { name: 'person_name', text: 'Usuario', type: CustomTableColumnType.TEXT },
  ];

  customTableStructure: CustomTableStructure;

  receiptStatusValues: typeof ReceiptStatusEnum = ReceiptStatusEnum;

  constructor(
    private router: Router,
    private APIPayments: ApiPaymentsService,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // this.getReceipts();
  }

  // getReceipts(status?: number) {
  //   this.APIPayments.getReceiptsByStatus(status).subscribe(response => {
  //     this.receiptList = response.data;

  //     this.customTableStructure = { columns: this.columns, data: this.receiptList };
  //     this.changeRef.detectChanges();
  //   });
  // }

  goToReceiptDetails(event: any) {
    this.router.navigate(['payment-receipt-details', event]);
  }

  onTabChange(event: MatTabChangeEvent) {
    switch (event.index) {
      case 0:
        this.tabSelected = 0;
        break;
      case 1:
        this.tabSelected = ReceiptStatusEnum.APPROVED;
        break;
      case 2:
        this.tabSelected = ReceiptStatusEnum.REJECTED;
        break;
    }
  }

  refreshTables() {
    this.pendingReceipts.getReceipts();
    this.approvedReceipts.getReceipts();
    this.rejectedReceipts.getReceipts();
  }
}
