<div class="base-view">
  <div
    style="
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      place-content: center space-between;
      align-items: center;
    "
  >
    <section-header
      title="Reporte por cursos"
      [showNewButton]="false"
    ></section-header>
    <div class="file-buttons">
      <button
        mat-flat-button
        color="primary"
        style="margin: 5px 10px"
        (click)="exportExcelStudentCourses()"
      >
        <mat-icon>file_download</mat-icon> LISTADO ALUMNOS
      </button>
      <button
        mat-flat-button
        color="primary"
        style="margin: 5px 10px"
        (click)="exportPaymentsStatus()"
      >
        <mat-icon>file_download</mat-icon> ESTADOS DE PAGO
      </button>
    </div>
  </div>

  <mat-card>
    <mat-card-header>
      <mat-form-field
        class="by-course-filter"
        appearance="outline"
        style="width: 50%"
      >
        <mat-label>Filtrar por Curso</mat-label>
        <mat-select (valueChange)="onCourseChange($event)">
          <mat-option value="0">-- Todos --</mat-option>
          <mat-option *ngFor="let c of courses" [value]="c.id">{{
            '(' + c.id + ') ' + c.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-header>
    <mat-card-content>
      <custom-table
        *ngIf="dataLoadedSC"
        [dataSource]="tableSC"
        [showActions]="true"
        [showGenericButton2]="true"
        (genericButton2Pressed)="goToDetails($event)"
        [genericButton2]="detailsBtn"
        [filterable]="true"
        genericButton2Title="Detalles de inscripción y pagos del usuario"
      >
      </custom-table>
    </mat-card-content>
  </mat-card>
</div>
