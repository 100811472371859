import { Component, HostListener, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { License } from 'src/app/models/license';
import { Person } from 'src/app/models/person';
import { ApiLicensesService } from 'src/app/services/api-licenses.service';
import { ApiPersonsService } from 'src/app/services/api-persons.service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-user-license',
  templateUrl: './user-license.component.html',
  styleUrls: ['./user-license.component.scss'],
})
export class UserLicenseComponent implements OnInit {
  personData: Person;
  licenses: License[] = [];
  personId: number;
  isPortrait: boolean;

  constructor(
    private identity: IdentityService,
    private APILicenses: ApiLicensesService,
    private APIPersons: ApiPersonsService,
    private ui: UserInterfaceService,
  ) {
    this.ui.spin$.next(true);
  }

  async ngOnInit(): Promise<void> {
    this.checkOrientation();
    this.personId = this.identity.getDecodedToken().id_person;
    this.getPerson();
    await this.getAllLicenses();
    this.ui.spin$.next(false);
  }

  getPerson() {
    this.APIPersons.getById(this.personId).subscribe(response => {
      this.personData = response.data;
    });
  }

  async getAllLicenses() {
    try {
      //get all Licenses instance from api
      this.licenses = await firstValueFrom(
        this.APILicenses.getAll(this.personId),
      ).then(response => {
        return response.data;
      });
    } catch (error) {
      console.error(error);
    }
  }

  checkOrientation() {
    this.isPortrait = window.innerHeight > window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkOrientation();
  }
}
