<div
  class="student-file-container"
  style="display: flex; flex-direction: column"
>
  <h2>{{ personFile.lastname }}, {{ personFile.firstname }}</h2>
  <mat-card style="margin-bottom: 15px">
    <mat-card-content>
      <div style="display: flex; flex-direction: row">
        <strong style="max-width: 50%; flex: 1 1 100%">Nacionalidad</strong>
        <span>{{ personFile.nationality_name }}</span>
      </div>
      <div style="display: flex; flex-direction: row">
        <strong style="max-width: 50%; flex: 1 1 100%">DNI</strong>
        <span>{{ personFile.identity_number }}</span>
      </div>
      <div style="display: flex; flex-direction: row">
        <strong style="max-width: 50%; flex: 1 1 100%">Fecha de Nac.</strong>
        <span>{{ personFile.birthdate | date: 'dd/MM/yyyy' }}</span>
      </div>
      <div style="display: flex; flex-direction: row">
        <strong style="max-width: 50%; flex: 1 1 100%">Domicilio</strong>
        <span>{{ personFile.address }}</span>
      </div>
      <div style="display: flex; flex-direction: row">
        <strong style="max-width: 50%; flex: 1 1 100%">CP</strong>
        <span>{{ personFile.postal_code }}</span>
      </div>
      <div style="display: flex; flex-direction: row">
        <strong style="max-width: 50%; flex: 1 1 100%">Provincia</strong>
        <span>{{ personFile.state_name }}</span>
      </div>
      <div style="display: flex; flex-direction: row">
        <strong style="max-width: 50%; flex: 1 1 100%">Localidad</strong>
        <span>{{ personFile.city_name }}</span>
      </div>
      <div style="display: flex; flex-direction: row">
        <strong style="max-width: 50%; flex: 1 1 100%">Email</strong>
        <span
          ><a href="mailto:{{ personFile.email }}">{{
            personFile.email
          }}</a></span
        >
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <div style="display: flex; flex-direction: row">
        <span style="margin-right: 10px; flex: 1 1 0%">
          <h4>Foto</h4>
          <img
            class="user-images"
            [src]="personFile.photo"
            (click)="showImage('Foto', personFile.photo)"
          />
        </span>
        <span style="margin-right: 10px; flex: 1 1 0%">
          <h4>DNI Frente</h4>
          <img
            class="user-images"
            [src]="personFile.identity_card_front"
            (click)="showImage('DNI Frente', personFile.identity_card_front)"
          />
        </span>
        <span style="margin-right: 10px; flex: 1 1 0%">
          <h4>DNI Dorso</h4>
          <img
            class="user-images"
            [src]="personFile.identity_card_back"
            (click)="showImage('DNI Dorso', personFile.identity_card_back)"
          />
        </span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
