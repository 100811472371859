<div class="base-view">
  <section-header title="Cursos" [showNewButton]="false"></section-header>

  <mat-card *ngIf="!availableCourses" class="closed-enrollments-alert">
    <mat-icon>warning</mat-icon> Las inscripciones a los cursos se encuentran
    cerradas.
  </mat-card>
  <mat-card>
    <mat-tab-group animationDuration="250ms">
      <mat-tab label="Mis cursos">
        <div class="p-4">
          <custom-table
            *ngIf="dataLoadedSC"
            [dataSource]="tableSC"
            [showActions]="true"
            [showDetailsButton]="true"
            (detailsButtonPressed)="goToCourseDetails($event)"
          ></custom-table>
        </div>
      </mat-tab>

      <mat-tab label="Todos los cursos">
        <div class="p-4">
          <custom-table
            *ngIf="dataLoadedAC"
            [dataSource]="tableAC"
            [showActions]="true"
            [showGenericButton1]="true"
            [genericButton1]="enrollBtn"
            (genericButton1Pressed)="showMoreInfo($event)"
          ></custom-table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
