import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Person } from 'src/app/models/person';
import { RecordListTable, RecordType } from 'src/app/models/record';
import { ApiRecordsService } from 'src/app/services/api-records.service';

@Component({
  selector: 'records-list',
  templateUrl: './records-list.component.html',
  styleUrls: ['./records-list.component.scss'],
})
export class RecordsListComponent implements OnInit {
  personsList: Person[] = [];
  recordTypeList: RecordType[] = [];
  recordList: RecordListTable = {};
  today: Date = new Date();

  constructor(
    private APIRecords: ApiRecordsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getRecordTypes();
  }

  newRecord() {
    console.log('new person for record');
    this.router.navigate(['record-form']);
  }

  goToEdit(id: number) {
    this.router.navigate(['record-form', id]);
  }

  getRecordTypes() {
    this.APIRecords.getTypes().subscribe(response => {
      this.recordTypeList = response.data
        .sort((a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name),
        )
        .reverse();
      console.log('Tipos de records: ', response.data);
    });
    return this.recordTypeList;
  }
}
