<div class="base-view">
  <section-header title="Comprobantes" [showNewButton]="false"></section-header>

  <mat-card>
    <mat-tab-group
      animationDuration="250ms"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab label="Pendientes">
        <div class="p-4">
          <receipts-by-status-table
            #pendingReceipts
            (refreshData)="refreshTables()"
          ></receipts-by-status-table>
        </div>
      </mat-tab>
      <mat-tab label="Aprobados">
        <div class="p-4">
          <receipts-by-status-table
            #approvedReceipts
            [status]="receiptStatusValues.APPROVED"
            (refreshData)="refreshTables()"
          ></receipts-by-status-table>
        </div>
      </mat-tab>
      <mat-tab label="Rechazados">
        <div class="p-4">
          <receipts-by-status-table
            #rejectedReceipts
            [status]="receiptStatusValues.REJECTED"
            (refreshData)="refreshTables()"
          ></receipts-by-status-table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
