<div class="base-view items-center">
  <div class="avoidFrame">
    <h1>{{ title }}</h1>
    <mat-card class="p-4">
      <form [formGroup]="courseForm" novalidate (ngSubmit)="save()">
        <div class="inputFieldLayout">
          <mat-form-field style="flex: 1 1 0%" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput type="text" formControlName="name" />
            <mat-error *ngIf="courseForm.get('name')?.hasError('required')">
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputFieldLayout">
          <mat-form-field
            style="margin-right: 10px; flex: 1 1 0%"
            appearance="outline"
          >
            <mat-label>Fecha Inicio</mat-label>
            <input matInput type="date" formControlName="start_date" />
            <mat-error
              *ngIf="courseForm.get('start_date')?.hasError('required')"
            >
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field style="flex: 1 1 0%" appearance="outline">
            <mat-label>Fecha Fin</mat-label>
            <input matInput type="date" formControlName="end_date" />
            <mat-error *ngIf="courseForm.get('end_date')?.hasError('required')">
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputFieldLayout">
          <mat-form-field class="inputMax50 inputFlex" appearance="outline">
            <mat-label>Días y Horarios</mat-label>
            <input matInput type="text" formControlName="day_time" />
            <mat-error *ngIf="courseForm.get('day_time')?.hasError('required')">
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="inputMax25 inputFlex" appearance="outline">
            <mat-label>Vacantes</mat-label>
            <input
              matInput
              type="number"
              step="1"
              min="0"
              formControlName="vacancies"
            />
            <mat-error
              *ngIf="courseForm.get('vacancies')?.hasError('required')"
            >
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            class="inputMax25 inputFlex"
            appearance="outline"
            (change)="updateTotalPrice()"
          >
            <mat-label>Cuotas</mat-label>
            <input
              matInput
              type="number"
              step="1"
              min="0"
              formControlName="fees"
            />
            <mat-error *ngIf="courseForm.get('fees')?.hasError('required')">
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputFieldLayout">
          <mat-form-field
            style="margin-right: 10px"
            appearance="outline"
            (change)="updateTotalPrice()"
          >
            <mat-label>Precio Matrícula</mat-label>
            <input
              matInput
              type="number"
              step="0.01"
              min="0"
              formControlName="enrollment_price"
            />
            <mat-error
              *ngIf="courseForm.get('enrollment_price')?.hasError('required')"
            >
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            style="margin-right: 10px; flex: 1 1 0%"
            appearance="outline"
            (change)="updateTotalPrice()"
          >
            <mat-label>Precio Cuota</mat-label>
            <input
              matInput
              type="number"
              step="0.01"
              min="0"
              formControlName="fee_price"
            />
            <mat-error
              *ngIf="courseForm.get('fee_price')?.hasError('required')"
            >
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            style="margin-right: 10px; flex: 1 1 0%"
            appearance="outline"
          >
            <mat-label>Precio Total</mat-label>
            <input matInput formControlName="total_price" />
            <mat-error
              *ngIf="courseForm.get('total_price')?.hasError('required')"
            >
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputFieldLayout">
          <mat-form-field style="flex: 1 1 0%" appearance="outline">
            <mat-label>Descripción del curso</mat-label>
            <textarea
              matInput
              formControlName="description"
              rows="5"
            ></textarea>
            <mat-error
              *ngIf="courseForm.get('description')?.hasError('required')"
            >
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputFieldLayout">
          <mat-form-field>
            <mat-checkbox
              color="primary"
              formControlName="enrollment_enabled"
              [(ngModel)]="enrollment_enabled"
              >Inscripciones habilitadas</mat-checkbox
            >
            <input matInput [hidden]="true" />
          </mat-form-field>
        </div>

        <div class="courses-buttons">
          <back-button></back-button>
          <button
            mat-flat-button
            class="button-save"
            color="primary"
            type="submit"
          >
            Guardar
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
