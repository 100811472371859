<div>
  <!-- <ngx-lottie width="500px" [options]="options"></ngx-lottie> -->
</div>
<div
  class="container-credential"
  [class.disabled]="!['valid', 'expired'].includes(credentialStatus)"
  [class.expired]="credentialStatus === 'expired'"
  [class.only-view]="onlyView"
>
  <div class="background-credential">
    <section class="qr-faboxeo">
      <img [src]="qrImage" />
      <span class="text-center"> N° {{ userData.identityNumber }}</span>
    </section>
    <div class="credential-content">
      <section class="credential-names">
        <lottie-component class="institution-logo"></lottie-component>

        <div class="d-flex flex-column datos title">
          <span>{{ userData.fullName }}</span>
          <span class="font-monospace text-uppercase fs-5 fw-normal range"
            >Cargo: {{ credentialData.typeName || 'Ninguno' }}</span
          >
        </div>
      </section>
      <section class="d-flex align-items-end datos description">
        <img class="align-items-xxl-end" [src]="userData.photo" />
        <div
          class="datos description description-container"
          style="align-items: flex-start"
        >
          <div class="datos">
            <mat-icon>person</mat-icon
            ><span> DNI: {{ userData.identityNumber }}</span>
          </div>
          <div class="datos">
            <mat-icon>location_on</mat-icon
            ><span> Provincia: {{ userData.location }}</span>
          </div>
          <div style="margin: 3px"></div>
          <div class="datos">
            <mat-icon>credit_card</mat-icon
            ><span class="valid_until">
              Vencimiento:
              <b>{{ formatExpiration(credentialData.valid_until) }}</b></span
            >
          </div>
        </div>
      </section>
    </div>
    <label
      class="form-label expired-title title"
      [class.disabled-title]="!['valid', 'expired'].includes(credentialStatus)"
      >{{ credentialType.toUpperCase() }}</label
    >
  </div>
</div>
