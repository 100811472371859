import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import packageJson from 'package.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const initializeSentry = () => {
  const sentryDsn: string | null =
    'https://a7cb653bfa6fb6e88fbe917e3f1fa41b@o4507257305759744.ingest.us.sentry.io/4507258425245697';
  const envSentry: string = environment.name || 'DEV';

  if (!sentryDsn || ['LOCAL', 'DEV'].includes(envSentry)) return;

  Sentry.init({
    dsn: sentryDsn,
    release: `${envSentry}@${packageJson.version}`,
    environment: envSentry,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin, environment.apiUrl],
      }),
      Sentry.replayCanvasIntegration(),
    ],
    beforeSend: event => {
      const token = localStorage.getItem('token');
      const decodedUser = token ? jwtDecode<JwtPayload>(token) : undefined;

      event.user = {
        user: decodedUser,
      };
      return event;
    },
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

if (environment.name === 'PROD') enableProdMode();

initializeSentry();
platformBrowserDynamic().bootstrapModule(AppModule);
