import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpBaseResponse } from '../models/base-response';
import { EnrollRequest } from '../models/enroll-requests';
import { AddPaymentRequest } from '../models/payment-requests';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiStudentsService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = 'students';
  }

  /**
   * @ngdoc function
   * @name getStudentsByStatus
   * @param statusId number
   * @methodOf ApiStudentsService
   * @description Se obtienen los alumnos según el estado enviado.
   * @return HttpBaseResponse
   */

  getStudentsByStatus(statusId: number) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/all_by_status/${statusId}`,
    );
  }

  /**
   * @ngdoc function
   * @name getStudentCourses
   * @param studentId number
   * @param courseId number
   * @methodOf ApiStudentsService
   * @description Se obtiene la información de los cursos del estudiante (o el curso si se envía el ID del mismo).
   * @return HttpBaseResponse
   */

  getStudentCourses(studentId?: number, courseId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/courses`;
    if (studentId) endpoint += `/${studentId}`;
    if (courseId) endpoint += `/${courseId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
   * @ngdoc function
   * @name addPayment
   * @param request AddPaymentRequest
   * @methodOf ApiStudentsService
   * @description Agrega un nuevo comprobante.
   * @return HttpBaseResponse
   */

  addPayment(request: AddPaymentRequest) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/add`,
      request,
    );
  }

  /**
   * @ngdoc function
   * @name enrollStudent
   * @param request EnrollRequest
   * @methodOf ApiStudentsService
   * @description Modifica la inscripción de un estudiante a un curso.
   * @return HttpBaseResponse
   */

  enrollStudent(request: EnrollRequest) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/enroll`,
      request,
    );
  }
}
