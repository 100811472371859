export class Person {
  id: number;
  id_user: number;
  firstname: string;
  lastname: string;
  birthdate: Date;
  identity_number: string;
  address: string;
  postal_code: string;
  id_nationality: number;
  id_state: number;
  id_city: number;
  gender: string;
  phone: string;
  email: string;
  photo: string;
  identity_card_front: string;
  identity_card_back: string;
  nationality_name: string;
  state_name: string;
  city_name: string;
}
