import { Component, OnInit } from '@angular/core';

import { TestComponent } from '../../components/test/test.component';

@Component({
  selector: 'app-test-view',
  templateUrl: './test.view.html',
  styleUrls: ['./test.view.scss'],
  standalone: true,
  imports: [TestComponent],
})
export class TestView implements OnInit {
  pepito = TestComponent.name;

  ngOnInit(): void {}
}
