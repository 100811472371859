<div class="base-view items-center">
  <section-header
    title="¡Hola {{ username }}, bienvenido a la Federación Argentina de Box!"
  ></section-header>
  <mat-card class="w-full max-w-3xl">
    <mat-card-content class="p-4">
      <div>
        <p class="mb-5 text-base">
          Accede rápidamente a las principales funciones de la federación:
        </p>
        <ul class="list-disc pl-5">
          <li class="mb-2 text-base">
            <strong class="font-semibold text-primary-500">Cursos:</strong>
            Inscríbete o consulta tu progreso.
          </li>
          <li class="mb-2 text-base">
            <strong class="font-semibold text-primary-500">Pagos:</strong>
            Revisa y gestiona tus pagos.
          </li>
          <li class="mb-2 text-base">
            <strong class="font-semibold text-primary-500"
              >Comprobantes:</strong
            >
            Descarga recibos y comprobantes.
          </li>
          <li class="mb-2 text-base">
            <strong class="font-semibold text-primary-500">Datos:</strong>
            Consulta y actualiza tus datos.
          </li>
          <li class="mb-2 text-base">
            <strong class="font-semibold text-primary-500">Legajos:</strong>
            Accede a tu historial y credenciales.
          </li>
        </ul>
        <p class="mb-5 text-base">
          Utiliza el menú lateral para navegar por estas opciones.
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
