<h2 mat-dialog-title>{{ elements.title }}</h2>
<mat-dialog-content>{{ elements.content }}</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onClickCancel()">
    {{ elements.closeButtonLabel }}
  </button>
  <button
    mat-button
    *ngIf="elements.okButtonLabel !== null"
    cdkFocusInitial
    [mat-dialog-close]="onClickOk()"
    color="primary"
  >
    {{ elements.okButtonLabel }}
  </button>
</mat-dialog-actions>
