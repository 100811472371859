import { Injectable } from '@angular/core';

import { PaymentStatusEnum } from '../enums/payment-status-enum';
import { ReceiptStatusEnum } from '../enums/receipt-status-enum';
import { SemaphoreColumn } from '../models/semaphore-column';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  receiptFileValidation(type: string) {
    let valid: boolean = false;

    switch (type) {
      case 'image/jpeg':
      case 'image/png':
      case 'application/pdf':
        valid = true;
        break;
    }

    return valid;
  }

  userFileValidation(type: string) {
    let valid: boolean = false;

    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        valid = true;
        break;
    }

    return valid;
  }

  isExpired(date: Date | string): boolean {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return new Date() > date;
  }

  ifSingleFee(
    fees: string | number,
    fee_price: number,
    enrollment_price: number,
  ) {
    const fees_schema = {
      fees: fees,
      fee_price: fee_price,
    };

    if (fees == 0) {
      fees_schema.fees = 'Única';
      fees_schema.fee_price = enrollment_price;
    }
    return fees_schema;
  }

  getValidEnabledBooleanSemaphore(enabled: boolean | string | undefined): any {
    let color: string = 'text-red-400 dark:text-red-500';
    let icon: string = 'pi pi-times';
    // enabled_status_name = "Deshabilitada";

    if (enabled == true) {
      // enabled_status_name = "Habilitada";
      color = 'text-green-400 dark:text-green-500';
      icon = 'pi pi-check';
    }

    return {
      value: enabled,
      icon: icon,
      class: color,
      background: 'transparent',
    };
  }

  getReceiptStatusSemaphore(receipt_status: number): any {
    let color: string = 'bg-surface-200 dark:bg-surface-500/30';
    let status: string = 'En Aprobación';

    switch (receipt_status?.toString()) {
      case ReceiptStatusEnum.APPROVED.toString():
        color = 'bg-green-300 dark:bg-green-600/60';
        status = 'Aprobado';
        break;
      case ReceiptStatusEnum.REJECTED.toString():
        color = 'bg-red-300 dark:bg-red-500/60';
        status = 'Rechazado';
        break;
    }

    const semaphore: SemaphoreColumn = {
      class: color,
      label: status,
    };

    return semaphore;
  }

  getPaymentStatusSemaphore(payment_status: number): any {
    let color: string = 'bg-surface-200 dark:bg-surface-500/30';
    let status_name = 'Pago Pendiente';

    switch (payment_status.toString()) {
      case PaymentStatusEnum.COMPLETE.toString():
        color = 'bg-green-300 dark:bg-green-600/60';
        status_name = 'Pago Completo';
        break;
      case PaymentStatusEnum.INCOMPLETE.toString():
        color = 'bg-amber-400/80 dark:bg-amber-600/80';
        status_name = 'Pago Incompleto';
        break;
    }
    const semaphore: SemaphoreColumn = {
      class: color,
      label: status_name,
    };
    return semaphore;
  }

  getValidLicenseStatusSemaphore(valid: boolean | string | undefined): any {
    let color: string = 'bg-red-300 dark:bg-red-500/60';
    let status_name = 'Vencida';

    if (valid === 'sin-configurar') {
      status_name = 'Sin Configurar';
      color = 'bg-surface-200 dark:bg-surface-500/30';
    } else if (valid) {
      status_name = 'Vigente';
      color = 'bg-green-300 dark:bg-green-600/60';
    }

    return {
      class: color,
      label: status_name,
    };
  }

  getValidCredentialStatusSemaphore(valid: boolean | string | undefined): any {
    let color: string = 'bg-red-300 dark:bg-red-500/60';
    let status_name = 'Vencida';

    if (valid === 'sin-configurar') {
      status_name = 'Sin Configurar';
      color = 'bg-surface-200 dark:bg-surface-500/30';
    } else if (valid) {
      status_name = 'Vigente';
      color = 'bg-green-300 dark:bg-green-600/60';
    }

    return {
      class: color,
      label: status_name,
    };
  }

  // getBoutResultSemaphore(bout_result: string, bout_method: string): any {
  //   let color: string = "#8ac926"; // GREEN;
  //   let status_name = "W";

  //   switch (bout_result) {
  //     case "L":
  //       color = "#fd7a7e"; // RED
  //       status_name = "Perdedor";
  //       break;
  //     case "D":
  //       color = "#8DCBE4"; // SKYBLUE
  //       status_name = "Empate";
  //       break;
  //   }

  //   return {
  //     class: color,
  //     label: status_name + "-" + bout_method,
  //   };
  // }
}
