<div class="base-view">
  <section-header title="Solicitudes de cambio"></section-header>

  <div style="flex: 1 1 1e-9px">
    <mat-card>
      <mat-card-content>
        <custom-table
          *ngIf="dataLoadedALL"
          [dataSource]="tableAllStudents"
          [paginatorPageSize]="20"
          [filterable]="true"
          [showActions]="true"
          [showGenericButton1]="true"
          [genericButton1]="detailsBtn"
          genericButton1Title="Detalles"
          (genericButton1Pressed)="goToDetails($event)"
        ></custom-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
