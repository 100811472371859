<div class="container-login bg-primary-500 dark:bg-primary-200">
  <div class="flex-box">
    <img src="assets/images/logoFooterFAB.webp" class="mb-2 ml-2" />
    <mat-card class="p-4">
      <form [formGroup]="loginForm" novalidate (ngSubmit)="login()">
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="username" />
          <mat-error *ngIf="loginForm.get('username')?.hasError('required')">
            Este campo es <strong>obligatorio.</strong>
          </mat-error>
          <mat-error *ngIf="loginForm.get('username')?.hasError('pattern')">
            Ingrese una casilla de correo válida.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" formControlName="password" />
          <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
            Este campo es <strong>obligatorio.</strong>
          </mat-error>
        </mat-form-field>

        <div class="login-buttons">
          <span class="flex-auto"></span>
          <button mat-flat-button color="primary" type="submit">
            Ingresar
          </button>
          <span class="flex-auto"></span>
        </div>
        <div class="login-buttons">
          <button
            type="button"
            mat-button
            color="link"
            (click)="goToForgotPassword()"
          >
            Olvidé mi contraseña
          </button>
          <button
            type="button"
            mat-button
            color="link"
            (click)="goToRegistration()"
          >
            Registrarme
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
