import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpBaseResponse } from '../models/base-response';
import { LoginRequest } from '../models/login-request';
import { RegistrationRequest } from '../models/registration-request';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiUsersService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = 'users';
  }

  /**
   * @ngdoc function
   * @name getUserPerson
   * @param userId number
   * @methodOf ApiUsersService
   * @description Se obtienen los usuarios según el estado enviado.
   * @return HttpBaseResponse
   */

  getUserPerson(userId: number, personId: number) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/user_person/${userId}/${personId}`,
    );
  }

  /**
   * @ngdoc function
   * @name register
   * @param request AddPaymentRequest
   * @methodOf ApiUsersService
   * @description Agrega un nuevo comprobante.
   * @return HttpBaseResponse
   */

  register(request: RegistrationRequest | Partial<RegistrationRequest>) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/form`,
      request,
    );
  }

  /**
   * @ngdoc function
   * @name emailActivation
   * @param request RegistrationRequest
   * @methodOf ApiUsersService
   * @description Reenviar email de activacion de cuenta.
   * @return HttpBaseResponse
   */

  emailActivation(personId: number) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/email_activation/${personId}`,
    );
  }

  /**
   * @ngdoc function
   * @name PasswordRecoveryEmail
   * @param request RegistrationRequest
   * @methodOf ApiUsersService
   * @description Reenviar email para recuperar contraseña.
   * @return HttpBaseResponse
   */

  PasswordRecoveryEmail(personId: number) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/email_recovery_password/${personId}`,
    );
  }

  /**
   * @ngdoc function
   * @name validateCredentials
   * @param request LoginRequest
   * @methodOf ApiUsersService
   * @description Se validan las credenciales para el login.
   * @return HttpBaseResponse
   */

  validateCredentials(request: LoginRequest) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/login`,
      request,
    );
  }

  /**
   * @ngdoc function
   * @name impersonatePerson
   * @param request any
   * @methodOf ApiUsersService
   * @description Se utiliza para impersonarse con otro correo
   * @return HttpBaseResponse
   */

  impersonatePerson(request: any) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/impersonate`,
      request,
    );
  }

  /**
   * @ngdoc function
   * @name validateUserByToken
   * @param token string
   * @methodOf ApiUsersService
   * @description Se validan que exista el usuario según su token.
   * @return HttpBaseResponse
   */

  validateUserByToken(token: string) {
    const data = { token: token };
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/validate_user`,
      data,
    );
  }

  /**
   * @ngdoc function
   * @name forgotPassword
   * @param email string
   * @methodOf ApiUsersService
   * @description Se valida que exista el usuario con el mail ingresado.
   * @return HttpBaseResponse
   */

  forgotPassword(email: string) {
    const data = { email: email };
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/forgot_password`,
      data,
    );
  }

  /**
   * @ngdoc function
   * @name changePassword
   * @param userId number
   * @param password string
   * @methodOf ApiUsersService
   * @description Se modifica el password del usuario.
   * @return HttpBaseResponse
   */

  changePassword(userId: number, password: string) {
    const data = { id_user: userId, password: password };
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/change_password`,
      data,
    );
  }
}
