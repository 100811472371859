import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiUsersService } from 'src/app/services/api-users.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private APIUsers: ApiUsersService,
    private ui: UserInterfaceService,
  ) {
    this.forgotForm = this.fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
        ],
      ],
    });
  }

  ngOnInit(): void {}

  sendValidationEmail() {
    if (this.forgotForm.valid) {
      this.ui.spin$.next(true);
      this.APIUsers.forgotPassword(
        this.forgotForm.get('email')!.value,
      ).subscribe({
        next: (response: any) => {
          this.forgotForm.get('email')?.setValue('');
          alert(
            'Enviamos un correo a la casilla de email ingresada. Si no lo recibió, no olvide revisar su carpeta de SPAM o CORREO NO DESEADO.',
          );
          this.ui.spin$.next(false);
          this.backToLogin();
        },
        error: () => {
          this.forgotForm.get('email')?.setValue('');
          this.ui.spin$.next(false);
          alert(
            'Ocurrió un error al validar el email ingresado o el mismo no corresponde a un usuario del sistema.',
          );
        },
      });
    }
  }

  backToLogin() {
    this.router.navigate(['login']);
  }
}
