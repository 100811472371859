import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { License } from 'src/app/models/license';
import { LicenseType } from 'src/app/models/license-type';
import { Person } from 'src/app/models/person';
import { ApiLicensesService } from 'src/app/services/api-licenses.service';
import { ApiPersonsService } from 'src/app/services/api-persons.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'license-dialog',
  templateUrl: './license-dialog.component.html',
  styleUrls: ['./license-dialog.component.scss'],
})
export class LicenseDialogComponent implements OnInit, OnChanges {
  licenseForm: UntypedFormGroup;
  personData: Person = new Person();
  license: License;
  licenses: License[] = [];
  licenseIndex: number = 0;
  licenseTypes: LicenseType[] = [];

  extendExpiration: boolean = false;
  validUntilNotSetted: boolean = false;

  creatingNewLicense: boolean = false;
  hasNoLicenses: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; personId: number },
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    private APILicenses: ApiLicensesService,
    private APIPersons: ApiPersonsService,
    private fb: UntypedFormBuilder,
    private ui: UserInterfaceService,
    private cdr: ChangeDetectorRef,
  ) {
    this.licenseForm = this.fb.group({
      enabled: [null, Validators.required],
      type: [null, Validators.required],
      valid_until: [null],
      id_person: [this.data.personId, Validators.required],
      id: [null],
    });

    this.ui.spin$.next(true);
    this.licenseForm.statusChanges.subscribe();

    this.licenseForm.get('enabled')!.valueChanges.subscribe(value => {
      if (value === true && this.license.id === null) {
        this.licenseForm.get('type')!.enable();
        this.licenseForm.get('valid_until')!.enable();
      } else {
        this.licenseForm.get('type')!.disable();
      }
      this.licenseForm.get('type')!.updateValueAndValidity();
      this.licenseForm.get('valid_until')!.updateValueAndValidity();
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getTypes();
    this.getPerson();
    await this.getAllLicenses();
    await this.getLicense();
    if (this.licenses.length == 0) {
      this.hasNoLicenses = true;
    }
    this.updateDialogSelectors();
    this.licenseForm.get('type')?.disable();
    this.ui.spin$.next(false);
  }

  ngOnChanges() {
    this.updateDialogSelectors();
  }

  getPerson() {
    this.APIPersons.getById(this.data.personId).subscribe(response => {
      this.personData = response.data;
    });
  }

  async getAllLicenses(id_person: number = 0) {
    try {
      this.licenses = await firstValueFrom(
        this.APILicenses.getAll(this.data.personId),
      ).then(response => {
        if (response.data?.enabled)
          response.data.enabled = response.data.enabled === '1';

        this.licenseForm.patchValue(response.data);
        if (response.data?.valid_until === null || !response.data) {
          this.validUntilNotSetted = true;
          this.licenseForm.get('type')?.enable();
          this.licenseForm
            .get('valid_until')
            ?.setValidators([Validators.required]);
          this.licenseForm.get('valid_until')?.updateValueAndValidity();
        }
        return response.data;
      });
    } catch (error) {
      console.error(error);
    }
  }
  async getLicense(license_id: number = 0) {
    try {
      if (this.licenses !== undefined && this.licenses?.length > 0) {
        this.license = this.licenses[license_id];
      }
    } catch (error) {
      console.error(error);
    }
  }

  createNewLicense() {
    this.creatingNewLicense = true;
    this.licenseForm.reset();
    this.licenseForm.get('enabled')?.setValue('0');
    this.licenseForm.get('type')?.enable();
    this.licenseForm.get('valid_until')?.enable();
    this.licenseForm.get('type')?.enable();
    this.licenseForm.get('id_person')?.setValue(this.data.personId);
    this.licenseForm.get('id')?.setValue(null);
    this.extendExpiration = true;
    this.license = this.licenseForm.getRawValue();
    if (this.licenses !== undefined && this.license !== undefined) {
      this.license.status = 'not-configured';
      this.licenses.push(this.license);
    }

    this.licenseIndex++;

    this.cdr.detectChanges(); // Manually trigger change detection
    this.updateDialogSelectors();
  }

  getNextLicense() {
    if (
      this.licenseIndex !== undefined &&
      this.licenseIndex < this.licenses?.length - 1
    ) {
      this.licenseIndex++;
      // this.license = this.licenses[this.licenseIndex];
      this.license = { ...this.licenses[this.licenseIndex] };
      //update status form
      this.updateDialogSelectors();
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  getPreviousLicense() {
    if (this.licenseIndex !== undefined && this.licenseIndex > 0) {
      this.licenseIndex--;
      // this.license = this.licenses[this.licenseIndex];
      this.license = { ...this.licenses[this.licenseIndex] };
      //update status form
      this.updateDialogSelectors();
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  updateDialogSelectors() {
    this.licenseForm
      .get('enabled')
      ?.setValue(this.license?.enabled == '1', false);
    this.licenseForm.get('type')?.setValue(this.license?.type);
  }

  async getTypes() {
    this.APILicenses.getTypes().subscribe(response => {
      this.licenseTypes = response.data;
    });
  }

  changeExpiration(event: Event) {
    if ((event.target as HTMLInputElement).value == 'yes') {
      this.extendExpiration = true;
      this.licenseForm.get('valid_until')?.setValidators([Validators.required]);
    } else {
      this.extendExpiration = false;
      this.licenseForm.get('valid_until')?.clearValidators();
    }
    this.licenseForm.get('valid_until')?.updateValueAndValidity();
  }

  cerrar() {
    this.dialogRef.close();
  }
  //   this.dialog
  //     .open(AlertDialogComponent, {
  //       data: {
  //         title: "¿Desea cerrar la ventana?",
  //         message: "Si cierra la ventana perderá los cambios realizados",
  //         buttons: [
  //           {
  //             text: "Cancelar",
  //             value: false,
  //             color: "warn",
  //           },
  //           {
  //             text: "Cerrar",
  //             value: true,
  //             color: "primary",
  //           },
  //         ],
  //       },
  //     })
  //     .afterClosed()
  //     .subscribe((response) => {
  //       if (response) {
  //         this.dialogRef.close();
  //       }
  //     });
  // }

  save(): void {
    this.creatingNewLicense = false;

    if (this.licenseForm.valid) {
      this.ui.spin$.next(true);
      // let regRequest: License = this.licenseForm.getRawValue();
      //! TODO: Caso en que el usuario no tiene ninguna licencia
      const regRequest: License = {
        enabled: this.licenseForm.get('enabled')?.value,
        id: this.licenses[this.licenseIndex]?.id || 0,
        id_person: this.licenseForm.get('id_person')?.value,
        status: this.license?.status,
        type: this.licenseForm.get('type')?.value,
        valid_until:
          this.licenseForm.get('valid_until')?.value ||
          this.licenses[this.licenseIndex].valid_until,
        updated_at: this.license?.updated_at || '',
      };

      this.APILicenses.create(regRequest).subscribe(response => {
        if (response.status == 201) {
          alert('Licencia Creada correctamente');
          this.dialogRef.close();
        } else if (response.status == 200 && response.code == 200) {
          alert('Licencia Modificada correctamente');
        } else if (response.status == 200 && response.code == 409) {
          alert(response.msg);
        } else {
          alert('Error al modificar la Licencia: ' + response.msg);
        }

        this.ui.spin$.next(false);
      });
    } else {
      Object.keys(this.licenseForm.controls).forEach(field => {
        const control = this.licenseForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
