<div class="base-view items-center">
  <div class="avoidFrame">
    <h1>{{ title }}</h1>
    <mat-card class="p-4">
      <form [formGroup]="licenseForm" novalidate (ngSubmit)="finish()">
        <div class="p-fluid p-grid form-container">
          <!-- Sección de encabezado -->
          <div class="p-col-12 p-md-8">
            <div class="header-section">
              <div>
                <p><strong>Fecha:</strong> {{ today | date: 'dd/MM/yyyy' }}</p>
                <p>
                  Por la presente solicito que me sea extendida la licencia
                  <strong>AMATEUR</strong> para la práctica de la disciplina de
                  boxeo.
                </p>
                <p>
                  <strong>Nota:</strong> La presente licencia es de uso personal
                  e intransferible.
                </p>
              </div>
              <div class="photo-section">
                <!-- [src]="licenseForm.get('photo')?.value" -->
                <p-image
                  src="https://static.vecteezy.com/system/resources/previews/005/544/718/non_2x/profile-icon-design-free-vector.jpg"
                  alt="Photo"
                  [style]="{ borderRadius: '15px', overflow: 'hidden' }"
                  width="100%"
                  [preview]="true"
                />
                <span>Foto de perfil</span>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <h2>Mis datos</h2>
            <hr class="divider" />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="lastname">Apellido</label>
            <input
              id="lastname"
              type="text"
              pInputText
              formControlName="lastname"
            />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="firstname">Nombre/s</label>
            <input
              id="firstname"
              type="text"
              pInputText
              formControlName="firstname"
            />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="identity_number">DNI</label>
            <input
              id="identity_number"
              type="text"
              pInputText
              formControlName="identity_number"
            />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="gender">Genero</label>
            <input
              id="gender"
              type="text"
              pInputText
              formControlName="gender"
            />
          </div>
          <div class="p-col-12 p-md-6">
            <label for="phone">Teléfono</label>
            <input id="phone" type="text" pInputText formControlName="phone" />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="email">Email</label>
            <input id="email" type="text" pInputText formControlName="email" />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="birthdate">Fecha de Nacimiento</label>
            <input
              id="birthdate"
              type="text"
              pInputText
              placeholder="dd/mm/aaaa"
              formControlName="birthdate"
            />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="id_nationality">Nacionalidad</label>
            <input
              id="id_nationality"
              type="text"
              pInputText
              formControlName="id_nationality"
            />
          </div>

          <!-- Domicilio y contacto -->
          <div class="p-col-12 p-md-6">
            <label for="id_state">Provincia</label>
            <input
              id="id_state"
              type="text"
              pInputText
              formControlName="id_state"
            />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="id_city">Localidad</label>
            <input
              id="id_city"
              type="text"
              pInputText
              formControlName="id_city"
            />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="address">Domicilio actual</label>
            <input
              id="address"
              type="text"
              pInputText
              formControlName="address"
            />
          </div>

          <div class="p-col-12 p-md-6">
            <label for="postal_code">Código Postal</label>
            <input
              id="postal_code"
              type="text"
              pInputText
              formControlName="postal_code"
            />
          </div>
          <div class="p-col-12">
            <h2>Datos a completar</h2>
            <hr class="divider" />
          </div>
          <div class="p-col-12 p-md-6">
            <label for="studies">Estudios</label>
            <p-select
              [options]="studiesOptions"
              placeholder="Seleccione"
              formControlName="studies"
              optionLabel="value"
            />
          </div>
          <br />
          <br />
          <br />
          <div class="p-col-12">
            <p-checkbox
              formControlName="declared_understand"
              inputId="declared_understand"
              binary="true"
            />
            <label for="declared_understand" class="ml-1"
              >Declaro conocer las Reglamentaciones vigentes y me obligo a
              respetarlas.</label
            >
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="licenses-buttons">
          <back-button></back-button>
          <button
            mat-flat-button
            class="button-save"
            color="primary"
            type="submit"
          >
            Finalizar
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
