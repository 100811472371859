<div class="base-view items-center">
  <div style="width: 90%; flex: 1 1 100%">
    <section-header title="Mis datos"></section-header>
    <mat-card class="p-4">
      <user-profile-form
        [userId]="userId"
        [personId]="personId"
      ></user-profile-form>
    </mat-card>
  </div>
</div>
