{
  "name": "sistema-fab",
  "homepage": "./",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "dev": "ng serve SistemaFab",
    "lint": "ng lint SistemaFab --fix",
    "format": "npx prettier --write .",
    "build": "ng build SistemaFab",
    "build-qa": "ng build SistemaFab-qa && npm run sentry:sourcemaps",
    "build-dev": "ng build SistemaFab-dev && npm run sentry:sourcemaps",
    "build-prod": "ng build SistemaFab-prod && npm run sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org mirkingo --project faboxeo-angular ./dist && sentry-cli sourcemaps upload --org mirkingo --project faboxeo-angular ./dist",
    "sentry:login": "sentry-cli login"
  },
  "private": true,
  "browserslist": [
    "last 1 chrome version",
    "last 1 firefox version",
    "last 1 safari version",
    ">0.2%",
    "not dead",
    "not op_mini all"
  ],
  "dependencies": {
    "@angular-devkit/core": "^18.2.8",
    "@angular/animations": "^18.2.7",
    "@angular/cdk": "^18.2.8",
    "@angular/common": "^18.2.7",
    "@angular/compiler": "^18.2.7",
    "@angular/core": "^18.2.7",
    "@angular/forms": "^18.2.7",
    "@angular/material": "^18.2.8",
    "@angular/platform-browser": "^18.2.7",
    "@angular/platform-browser-dynamic": "^18.2.7",
    "@angular/router": "^18.2.7",
    "@sentry/angular": "^8.33.1",
    "@sentry/cli": "^2.37.0",
    "ajv": "^8.17.1",
    "chart.js": "^4.4.4",
    "crypto-js": "^4.2.0",
    "jwt-decode": "^4.0.0",
    "lottie-web": "^5.12.2",
    "moment": "^2.30.1",
    "ngx-lottie": "^12.0.0",
    "ngx-scanner-qrcode": "^1.6.9",
    "node-forge": "^1.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^18.0.0-beta.2",
    "qrcode": "^1.5.4",
    "rxjs": "^7.8.1",
    "selfsigned": "^2.4.1",
    "signature_pad": "^5.0.3",
    "tailwindcss-primeui": "^0.3.4",
    "terser": "^5.34.1",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.8",
    "@angular-eslint/builder": "18.3.1",
    "@angular-eslint/eslint-plugin": "18.3.1",
    "@angular-eslint/eslint-plugin-template": "18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "18.3.1",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.7",
    "@angularclass/hmr": "^3.0.0",
    "@types/crypto-js": "^4.2.2",
    "@types/jasmine": "^5.1.4",
    "@types/jest": "^29.5.13",
    "@types/node": "^22.7.5",
    "@types/qrcode": "^1.5.5",
    "@typescript-eslint/eslint-plugin": "8.8.1",
    "@typescript-eslint/parser": "8.8.1",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.12.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-simple-import-sort": "^12.1.1",
    "eslint-plugin-unused-imports": "^4.1.4",
    "jasmine-core": "^5.3.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "postcss": "^8.4.47",
    "prettier": "^3.3.3",
    "prettier-eslint": "^16.3.0",
    "prettier-plugin-tailwindcss": "^0.6.8",
    "tailwindcss": "^3.4.13",
    "typescript": "^5.5.2"
  }
}
