import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import { UserProfilesEnum } from '../enums/user-profiles-enum';
import { LoginRequest } from '../models/login-request';
import { UserLogged } from '../models/user-logged';
import { ApiUsersService } from './api-users.service';

// TESORERIA
const genericToken: string =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c2VyIjoiMSIsImlkX3BlcnNvbiI6bnVsbCwiaWRfcHJvZmlsZSI6IjEiLCJpZF9zdHVkZW50IjpudWxsfQ.lpeZQoJD9FkNZtRil612fGE5mViMyhKqDo4UdDV7AgY';
// ALUMNO
// const token: string = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c2VyIjoiMyIsImlkX3BlcnNvbiI6IjEiLCJpZF9wcm9maWxlIjoiMyJ9.5NwCGYdJx8PdqxqoESWm0eKeeee-zxEeGZgqmYGnWZU";
// VER ESTO DE LOS TOKEN
@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  private loggedUser = new BehaviorSubject<any>(this.getDecodedToken());
  private loggedIn = new BehaviorSubject<boolean>(this.tokenExists());

  private useLegajo = new BehaviorSubject<boolean>(
    this.getUseLegajoFromToken(),
  );
  private legajoId = new BehaviorSubject<number | string | undefined>(
    this.getLegajoIdFromToken(),
  );

  private extraProfiles = new BehaviorSubject<number[]>(
    this.getExtraProfilesFromToken(),
  );

  private useCredential = new BehaviorSubject<boolean>(
    this.getUseCredentialFromToken(),
  );
  private useLicense = new BehaviorSubject<boolean>(
    this.getUseLicenseFromToken(),
  );

  get getLoggedUser() {
    return this.loggedUser.asObservable();
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get getAllProfiles(): number[] {
    return [
      this.loggedUser.getValue().id_profile,
      ...this.extraProfiles.getValue(),
    ];
  }

  get getUseCredential() {
    return this.useCredential.asObservable();
  }

  get getUseLicense() {
    return this.useLicense.asObservable();
  }

  get getUseLegajo() {
    return this.useLegajo.asObservable();
  }

  get getLegajoId() {
    return this.legajoId.asObservable();
  }

  constructor(
    private APIUsers: ApiUsersService,
    private router: Router,
  ) {
    const legajoId = this.getLegajoIdFromToken();
    this.legajoId.next(legajoId);
  }

  login(user: LoginRequest) {
    this.APIUsers.validateCredentials(user).subscribe({
      next: (loggedUser: UserLogged) => {
        if (loggedUser.active == '1') {
          this.loggedIn.next(true);
          this.extraProfiles.next(loggedUser.extra_profiles);
          this.useLegajo.next(loggedUser.use_legajo !== null);
          this.legajoId.next(loggedUser.id_person);
          this.useLicense.next(loggedUser.use_license !== null);
          this.useCredential.next(loggedUser.use_credential !== null);
          localStorage.setItem('token', loggedUser.token);

          this.loggedUser.next(loggedUser);

          const todayDate = new Date();
          todayDate.setHours(todayDate.getHours() + 2);
          localStorage.setItem('expired', todayDate.toISOString());

          switch (loggedUser.id_profile) {
            case UserProfilesEnum.TREASURY:
              this.router.navigate(['payment-receipts-list']);
              break;

            case UserProfilesEnum.SCHOOL:
              this.router.navigate(['courses-list']);
              break;

            case UserProfilesEnum.SECRETARY:
              this.router.navigate(['courses-list']);
              break;

            case UserProfilesEnum.USER:
              this.router.navigate(['student-courses']);
              break;

            case UserProfilesEnum.ADMIN:
              this.router.navigate(['test']);
              break;
          }
        } else {
          alert(
            'El usuario no se encuentra activado. Al registrarse le hemos enviado un correo, en caso de no recibirlo puede intentar activar su usuario mediante el botón de OLVIDÉ MI CONTRASEÑA.',
          );
        }
      },
      error: e => {
        alert(
          'El email y contraseña no corresponden a un usuario del sistema.',
        );
      },
    });
  }

  impersonate(impersonateRequest: any) {
    this.APIUsers.impersonatePerson(impersonateRequest).subscribe({
      next: (loggedUser: UserLogged) => {
        if (loggedUser.active == '1') {
          this.loggedIn.next(true);
          this.useCredential.next(loggedUser.use_credential !== null);
          localStorage.setItem('token', loggedUser.token);

          const todayDate = new Date();
          todayDate.setHours(todayDate.getHours() + 2);
          localStorage.setItem('expired', todayDate.toISOString());

          switch (loggedUser.id_profile) {
            case UserProfilesEnum.TREASURY:
              this.router.navigate(['payment-receipts-list']);
              break;

            case UserProfilesEnum.SCHOOL:
              this.router.navigate(['courses-list']);
              break;

            case UserProfilesEnum.SECRETARY:
              this.router.navigate(['courses-list']);
              break;

            case UserProfilesEnum.USER:
              this.router.navigate(['student-courses']);
              break;

            case UserProfilesEnum.ADMIN:
              this.router.navigate(['test']);
              break;
          }
        } else {
          alert(
            'El usuario no se encuentra activado. Al registrarse le hemos enviado un correo, en caso de no recibirlo puede intentar activar su usuario mediante el botón de OLVIDÉ MI CONTRASEÑA.',
          );
        }
      },
      error: e => {
        alert(
          'El email y contraseña no corresponden a un usuario del sistema.',
        );
      },
    });
  }

  logout(redirect: boolean = true) {
    this.loggedIn.next(false);
    localStorage.clear();
    sessionStorage.clear();

    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }

    // window.history.pushState('', '', "/login");
    redirect && window.location.replace(window.location.href);
  }

  getDecodedToken() {
    if (!localStorage.getItem('token')) {
      return 'invalid';
    }
    const token = localStorage.getItem('token');
    const decoded = jwtDecode<JwtPayload>(token!);
    return <any>decoded;
  }

  getToken() {
    let token = genericToken;
    if (this.tokenExists()) {
      token = localStorage.getItem('token')!;
    }
    return token;
  }

  tokenExists(): boolean {
    return !!localStorage.getItem('token');
  }

  validateToken(token: string) {
    if (!token || token === 'invalid') {
      throw new Error('Invalid token specified');
    }
  }

  getLoggedUserName(): string {
    const loggedUser = this.getDecodedToken();

    if (loggedUser !== 'invalid') {
      return loggedUser.user_firstname
        ? `${loggedUser.user_firstname} ${loggedUser.user_lastname}`
        : `${loggedUser.role_type}: ${loggedUser.role_name || ''}`;
    }
    return 'Default User';
  }

  getExtraProfilesFromToken(): number[] {
    if (localStorage.getItem('token')) {
      return this.getDecodedToken().extra_profiles;
    } else {
      return [];
    }
  }

  getUseLegajoFromToken(): boolean {
    if (localStorage.getItem('token')) {
      return this.getDecodedToken().use_legajo !== null;
    } else {
      return false;
    }
  }

  getLegajoIdFromToken(): number | undefined {
    if (localStorage.getItem('token')) {
      if (this.getDecodedToken().id_person !== null) {
        return this.getDecodedToken().id_person;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getUseCredentialFromToken(): boolean {
    if (localStorage.getItem('token')) {
      return this.getDecodedToken().use_credential !== null;
    } else {
      return false;
    }
  }

  getUseLicenseFromToken(): boolean {
    if (localStorage.getItem('token')) {
      return this.getDecodedToken().use_license !== null;
    } else {
      return false;
    }
  }
}
