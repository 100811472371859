import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpBaseResponse } from '../models/base-response';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiPersonsService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = 'persons';
  }

  /**
   * @ngdoc function
   * @name getAll
   * @param personId number
   * @methodOf ApiPersonsService
   * @description Se obtiene la información de la tabla 'Persons'.
   * @return HttpBaseResponse
   */

  getAll(personId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
   * @ngdoc function
   * @name getAllBoxers
   * @param personId number
   * @methodOf ApiPersonsService
   * @description Se obtiene la información de la tabla 'Persons' y que sean boxeadores.
   * @return HttpBaseResponse
   */

  getAllBoxers(personId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/boxers`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
   * @ngdoc function
   * @name getById
   * @param personId number
   * @methodOf ApiPersonsService
   * @description Se obtiene la información de la tabla 'Persons'.
   * @return HttpBaseResponse
   */

  getById(personId: number) {
    const endpoint = `${this._apiUrl}/${this._controller}/get_person_by_id/${personId}`;
    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
   * @ngdoc function
   * @name getList
   * @param personId number
   * @methodOf ApiPersonsService
   * @description Se obtiene la información de las personas con formato para los listados.
   * @return HttpBaseResponse
   */

  getList(personId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/list`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }
}
