import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { IdentityService } from '../services/identity.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private identity: IdentityService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.identity.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['/login']);
          return false;
        }
        this.checkCookies();
        const profiles = next.data['roles'] || [];

        if (profiles.length < 1) return true;

        const allProfiles = this.identity.getAllProfiles;

        if (profiles.find((prof: number) => allProfiles.includes(prof)))
          return true;

        this.router.navigate(['/']);
        return false;
      }),
    );
  }

  checkCookies() {
    const expirationDate = localStorage.getItem('expired');
    const token = localStorage.getItem('token');
    const todayDate = new Date();

    if (!expirationDate || !token) {
      console.log('Cache not set or token missing. Redirecting to login.');
      todayDate.setHours(todayDate.getHours() + 2);
      localStorage.setItem('expired', todayDate.toISOString());
      this.router.navigate(['/login']);
      return;
    }

    const expiryDate = new Date(expirationDate);

    if (todayDate > expiryDate) {
      console.log('Token expired. Redirecting to login.');
      this.identity.logout();
      return;
    }

    try {
      // Assuming you have a method to validate the token
      this.identity.validateToken(token);
    } catch (error) {
      console.error('Invalid token specified', error);
      this.router.navigate(['/login']);
    }
  }
}
