<h2 *ngIf="!creatingNewCredential" mat-dialog-title>{{ data.title }}</h2>
<h2 *ngIf="creatingNewCredential" mat-dialog-title>Nueva Credencial</h2>

<div style="position: relative">
  <button
    class="new-credential-button"
    *ngIf="credentialIndex == credentials.length - 1 && !creatingNewCredential"
    mat-flat-button
    color="primary"
    (click)="createNewCredential()"
  >
    <mat-icon>add</mat-icon>Nueva
  </button>
</div>
<div class="card-content" mat-dialog-content [formGroup]="enrollmentForm">
  <div class="user-credential" *ngIf="personData && credential !== undefined">
    <credential-horizontal [credential]="credential" [person]="personData">
    </credential-horizontal>
  </div>
  <div
    *ngIf="!creatingNewCredential && !hasNoCredentials"
    class="credentials-paginator-buttons"
  >
    <button
      [disabled]="credentialIndex == 0"
      mat-flat-button
      color="primary"
      (click)="getPreviousCredential()"
    >
      Anterior
    </button>
    <div>{{ credentialIndex + 1 }}/{{ credentials.length }}</div>
    <button
      [disabled]="credentialIndex == credentials.length - 1"
      mat-flat-button
      color="primary"
      (click)="getNextCredential()"
    >
      Siguiente
    </button>
  </div>

  <div class="form-control">
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Estado</mat-label>
      <mat-select formControlName="enabled">
        <mat-option [value]="false">Inhabilitada</mat-option>
        <mat-option [value]="true">Habilitada</mat-option>
      </mat-select>
      <mat-error *ngIf="enrollmentForm.get('enabled')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Tipo de credencial</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of credentialTypes" [value]="type.code">{{
          type.name
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="enrollmentForm.get('type')?.hasError('required')">
        Este campo es <strong>obligatorio.</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div
    class="form-control"
    *ngIf="
      enrollmentForm.get('enabled')?.value === true || creatingNewCredential
    "
  >
    <div class="group-gap" *ngIf="!validUntilNotSetted">
      <h5 class="mb-3">¿Desea modificar la fecha de vencimiento?</h5>
      <div class="d-block my-3">
        <div class="custom-control custom-radio">
          <input
            id="yes"
            type="radio"
            class="custom-control-input"
            value="yes"
            [checked]="extendExpiration"
            (change)="changeExpiration($event)"
          />
          <label class="custom-control-label" style="font-size: 17px" for="yes"
            >SI</label
          >
        </div>

        <div class="custom-control custom-radio">
          <input
            id="no"
            type="radio"
            class="custom-control-input"
            value="no"
            [checked]="!extendExpiration"
            (change)="changeExpiration($event)"
          />
          <label class="custom-control-label" style="font-size: 17px" for="no"
            >NO</label
          >
        </div>
      </div>
    </div>

    <mat-form-field
      appearance="outline"
      class="form-field"
      style="margin: 20px 0px 0px 20px"
      *ngIf="
        creatingNewCredential ||
        (enrollmentForm.get('enabled')?.value === true &&
          (extendExpiration || validUntilNotSetted))
      "
    >
      <mat-label>Nueva fecha de vencimiento</mat-label>
      <input matInput type="date" formControlName="valid_until" />
      <!-- <mat-error *ngIf="regForm.get('birthdate')?.hasError('required')">
            Este campo es <strong>obligatorio.</strong>
          </mat-error> -->
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cerrar</button>
  <button mat-flat-button color="primary" (click)="save()">Guardar</button>
</div>
