import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'flow-request-dialog',
  templateUrl: './flow-request-dialog.component.html',
  styleUrls: ['./flow-request-dialog.component.scss'],
})
export class FlowRequestDialogComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() nodeData: TreeNode[] = [];
  @Input() keyNodeActive: string | null = null;
  @Output() onClose = new EventEmitter<void>();

  flowRequest: TreeNode[] = [];

  constructor() {}

  ngOnInit() {
    this.flowRequest = JSON.parse(JSON.stringify(this.nodeData));
    this.flowRequest.forEach(node =>
      this.updateNodeStyles(node, this.keyNodeActive || ''),
    );
  }

  closeDialog() {
    this.onClose.emit();
  }

  updateNodeStyles(node: TreeNode, activeKey: string): boolean {
    const isActive = node.key === activeKey;
    let childFound = false;

    node.styleClass = isActive ? 'node-active' : '';

    if (node.children) {
      for (const child of node.children) {
        childFound = this.updateNodeStyles(child, activeKey);
        if (childFound) {
          node.styleClass = 'node-passed';
          break;
        }
      }
    }

    return isActive || childFound;
  }
}
