<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  <license-horizontal [onlyView]="true" [person]="person"></license-horizontal>
</div>

<form [formGroup]="licenseCreateForm">
  <mat-form-field appearance="outline" class="form-field">
    <mat-label>Tipo de licencia</mat-label>
    <mat-select formControlName="type">
      <mat-option *ngFor="let type of licenseTypes" [value]="type.code">{{
        type.name
      }}</mat-option>
    </mat-select>
    <mat-error *ngIf="licenseCreateForm.get('type')?.hasError('required')">
      Este campo es <strong>obligatorio.</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="form-field"
    style="margin: 20px 0px 0px 20px"
  >
    <mat-label>Vigencia hasta</mat-label>
    <input matInput type="date" formControlName="valid_until" />
    <mat-error
      *ngIf="licenseCreateForm.get('valid_until')?.hasError('required')"
    >
      Este campo es <strong>obligatorio.</strong>
    </mat-error>
  </mat-form-field>
</form>
<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cerrar</button>
  <button
    mat-flat-button
    mat-dialog-close
    color="primary"
    [disabled]="licenseCreateForm.invalid"
    (click)="crearLicencia()"
  >
    Crear licencia
  </button>
</div>
