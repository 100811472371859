import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Course } from 'src/app/models/course';

import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-course-details-dialog',
  templateUrl: './course-details-dialog.component.html',
  styleUrls: ['./course-details-dialog.component.scss'],
})
export class CourseDetailsDialogComponent implements OnInit {
  panelOpenState: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Course,
    private router: Router,
    private alertDialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  goToAddPayment() {
    if (this.data.enrollment_enabled == '1') {
      this.router.navigate(['add-payment', this.data.id]);
    } else {
      const dialogRef = this.alertDialog.open(AlertDialogComponent, {
        data: {
          title: 'Inscripción al curso',
          content: 'Este curso se encuentra deshabilitado para la inscripción.',
          status: 'warning',
          closeButtonLabel: 'Cerrar',
        },
      });
    }
  }
}
