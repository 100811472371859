import { Component, Input, OnChanges, OnInit } from '@angular/core';
import QRCode from 'qrcode';
import { Credential } from 'src/app/models/credential';
import { Person } from 'src/app/models/person';

import { CustomLottieComponent } from '../lottie/lottie.component';

@Component({
  selector: 'credential-horizontal',
  templateUrl: './credential-horizontal.component.html',
  styleUrls: ['./credential-horizontal.component.scss'],
})
export class CredentialHorizontalComponent implements OnInit, OnChanges {
  @Input() credential: Credential;
  @Input() person: Person;
  @Input() onlyView: boolean = false;

  userData: any = {};
  credentialType: string;
  credentialData: any = {};
  qrImage: string = 'assets/images/baseQR.webp';
  expirationTime = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
  credentialStatus: string = 'not-configured';
  institutionLogo = CustomLottieComponent;

  ngOnInit(): void {
    if (this.credential?.status) {
      this.credentialStatus = this.credential.status;
    }
    this.credentialType =
      {
        valid: 'Valida',
        expired: 'Vencida',
        disabled: 'Inhabilitada',
        'not-configured': 'No configurada',
      }[this.credentialStatus] || 'No configurada';

    this.userData = {
      personId: this.credential?.id_person || ' - ',
      location: `${this.person.state_name || ' - '}, ${this.person.city_name || ' - '}`,
      fullName: `${this.person.firstname || ' - '} ${this.person.lastname || ' - '}`,
      identityNumber: Number(this.person.identity_number).toLocaleString(),
      gender: this.person.gender,
      photo: this.person.photo,
    };

    // Capaz reloj abajo
    // Sacar infinite animation
    // Mas blur y oscuro

    this.credentialData = {
      status: this.credentialStatus,
      type: this.credential?.type,
      typeName: this.credential?.typeName,
      valid_until: this.credential?.valid_until,
    };

    this.startExpirationTimer();
  }

  formatExpiration(date: string) {
    if (!date) return ' - ';
    const dateObject = new Date(date);
    const month = dateObject.toLocaleString('default', { month: 'long' });
    const year = dateObject.getFullYear();

    return `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
  }

  generateQR(userData: any) {
    //! EN CASO DE NO QUERER GENERAR NINGUN QR SI ESTA VENCIDO DESCOMENTAR
    // if (this.credentialStatus === "expired") return

    const optionQR: object = {
      errorCorrectionLevel: 'H',
      margin: 1,
      maskPattern: 5,
      color: {
        dark: '#333333',
        // light: "#00000000"
      },
    };
    const dataSended = JSON.stringify([
      userData.personId,
      this.credentialStatus === 'expired',
      Date.now(),
    ]);
    QRCode.toDataURL(dataSended, optionQR)
      .then((url: any) => {
        this.qrImage = url;
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  startExpirationTimer() {
    const userExist = Object.keys(this.userData).length !== 0;

    if (userExist) {
      this.generateQR(this.userData);
      setInterval(() => {
        this.generateQR(this.userData);
      }, this.expirationTime);
    }
  }
}
